.mhww-container {
  display: none !important;
  align-items: flex-start;
  height: 2510px;
  @media (max-width: 670px) {
    height: 2160px;
  }
  @media (max-width: 585px) {
    height: 1860px;
  }
  @media (max-width: 500px) {
    height: 1540px;
  }
  @media (max-width: 420px) {
    height: 1220px;
  }
  @media (max-width: 345px) {
    height: 1060px;
  }
  @media (max-width: 799px) {
    display: flex !important;
  }
}

.google-pixel-6-pro-how-we-work {
  align-items: flex-end;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  height: 1625px;
  overflow: hidden;
  padding: 25.5px 0;
  position: relative;
  width: 412px;
  transform: translateY(-50%) scale(1.6) translateY(50%);
  @media (max-width: 670px) {
    transform: translateY(-50%) scale(1.4) translateY(50%);
  }
  @media (max-width: 585px) {
    transform: translateY(-50%) scale(1.2) translateY(50%);
  }
  @media (max-width: 500px) {
    transform: unset;
  }
  @media (max-width: 420px) {
    transform: translateY(-50%) scale(0.8) translateY(50%);
  }
  @media (max-width: 345px) {
    transform: translateY(-50%) scale(0.7) translateY(50%);
  }
}

.google-pixel-6-pro-how-we-work {
  .atkinsonhyperlegible-bold-bunker-18px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 18px;
  }
  .atkinsonhyperlegible-normal-bunker-18px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .atkinsonhyperlegible-bold-bunker-35px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
  }
}

.google-pixel-6-pro-how-we-work .title {
  align-self: center;
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.54px;
  line-height: 30px;
  margin-right: 4px;
  min-height: 66px;
  width: 364px;
}

.google-pixel-6-pro-how-we-work .mhww-ns-0 {
  display: flex;
  height: 62px;
  margin-right: 4.21px;
  margin-top: 24px;
  width: 374px;
}

.google-pixel-6-pro-how-we-work .mhww-group-35 {
  display: flex;
  flex: 1;
  margin-bottom: 0;
  margin-top: 0;
  width: 376px;
}

.google-pixel-6-pro-how-we-work .mhww-risk-free-deployment {
  -webkit-text-stroke: 1px transparent;
  height: 64px;
  letter-spacing: 0.35px;
  line-height: 35px;
  margin-top: -4px;
  white-space: nowrap;
  width: 376px;
}

.google-pixel-6-pro-how-we-work .span0 {
  letter-spacing: 0.18px;
}

.google-pixel-6-pro-how-we-work .mhww-rivelins-committed {
  align-self: center;
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 19px;
  margin-top: 16px;
  min-height: 287px;
  width: 365px;
}

.google-pixel-6-pro-how-we-work .overlap-group1 {
  height: 473px;
  margin-right: -38px;
  margin-top: 18px;
  position: relative;
  width: 460px;
}

.google-pixel-6-pro-how-we-work .mhww-ns-1 {
  display: flex;
  height: 97px;
  left: 43px;
  position: absolute;
  top: 376px;
  width: 417px;
}

.google-pixel-6-pro-how-we-work .mhww-pricing-modelyour-n {
  height: 99px;
  letter-spacing: 0.35px;
  line-height: 35px;
  width: 417px;
}

.google-pixel-6-pro-how-we-work .ns1-close-up {
  height: 376px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 430px;
}

.google-pixel-6-pro-how-we-work .mhww-are-you-comfortable {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 2px;
  margin-top: 29px;
  min-height: 135px;
  width: 376px;
}

.google-pixel-6-pro-how-we-work .mhww-mask-group-4 {
  height: 309px;
  margin-top: 26px;
  object-fit: cover;
  width: 412px;
}
