.copyright-note,
.copyright-note-1,
.copyright-note-2,
.copyright-note-3,
.copyright-note-4,
.copyright-note-5,
.copyright-note-6,
.copyright-note-7,
.copyright-note-8,
.copyright-note-9,
.copyright-note-10,
.copyright-note-11 {
  display: flex;
  height: 20px;
  width: 186px;
}

.copyright,
.copyright-1,
.copyright-2,
.copyright-3,
.copyright-4,
.copyright-5,
.copyright-6,
.copyright-7,
.copyright-8,
.copyright-9,
.copyright-10,
.copyright-11 {
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-right: -2px;
  white-space: nowrap;
  width: 186px;
}
