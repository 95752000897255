.privacy-policy-link {
  display: flex;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 97px;
}

.privacy-policy {
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  width: 97px;
}

.privacy-policy-link.privacy-policy-link-1,
.privacy-policy-link.privacy-policy-link-3,
.privacy-policy-link.privacy-policy-link-5,
.privacy-policy-link.privacy-policy-link-7,
.privacy-policy-link.privacy-policy-link-9,
.privacy-policy-link.privacy-policy-link-11,
.privacy-policy-link.privacy-policy-link-13,
.privacy-policy-link.privacy-policy-link-15,
.privacy-policy-link.privacy-policy-link-17,
.privacy-policy-link.privacy-policy-link-19,
.privacy-policy-link.privacy-policy-link-21,
.privacy-policy-link.privacy-policy-link-23 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.privacy-policy-link.privacy-policy-link-1:hover,
.privacy-policy-link.privacy-policy-link-3:hover,
.privacy-policy-link.privacy-policy-link-5:hover,
.privacy-policy-link.privacy-policy-link-7:hover,
.privacy-policy-link.privacy-policy-link-9:hover,
.privacy-policy-link.privacy-policy-link-11:hover,
.privacy-policy-link.privacy-policy-link-13:hover,
.privacy-policy-link.privacy-policy-link-15:hover,
.privacy-policy-link.privacy-policy-link-17:hover,
.privacy-policy-link.privacy-policy-link-19:hover,
.privacy-policy-link.privacy-policy-link-21:hover,
.privacy-policy-link.privacy-policy-link-23:hover {
  opacity: 1;
}

.privacy-policy-link.privacy-policy-link-1 .privacy-policy,
.privacy-policy-link.privacy-policy-link-3 .privacy-policy,
.privacy-policy-link.privacy-policy-link-5 .privacy-policy,
.privacy-policy-link.privacy-policy-link-7 .privacy-policy,
.privacy-policy-link.privacy-policy-link-9 .privacy-policy,
.privacy-policy-link.privacy-policy-link-11 .privacy-policy,
.privacy-policy-link.privacy-policy-link-13 .privacy-policy,
.privacy-policy-link.privacy-policy-link-15 .privacy-policy,
.privacy-policy-link.privacy-policy-link-17 .privacy-policy,
.privacy-policy-link.privacy-policy-link-19 .privacy-policy,
.privacy-policy-link.privacy-policy-link-21 .privacy-policy,
.privacy-policy-link.privacy-policy-link-23 .privacy-policy {
  text-decoration: underline;
}
