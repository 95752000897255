.twitter,
.twitter-1,
.twitter-2,
.twitter-3,
.twitter-4,
.twitter-5,
.twitter-6,
.twitter-7,
.twitter-8,
.twitter-9,
.twitter-10,
.twitter-11 {
  background-image: url(../../static/img/twitter-1@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 21px;
  margin-top: 1.31px;
  width: 26px;
}
