.l-see-it-in-action {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100vw;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (max-width: 1279px) {
    max-width: 799px;
  }
}

.l-see-it-in-action .nav-bar-12 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 242px;
  z-index: 3;
}

.l-see-it-in-action .place-13 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.l-see-it-in-action .angles-right-2 {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.l-see-it-in-action .who-we-are-placeholder-2 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 8px;
  min-height: 29px;
  min-width: 156px;
  text-align: center;
  white-space: nowrap;
}

.l-see-it-in-action .span0-5 {
  text-decoration: underline;
}

.l-see-it-in-action .videos {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 80px;
  z-index: 4;
  row-gap: 160px;
  column-gap: 80px;
  @media (min-width: 799px) and (max-width: 1877px) {
    gap: 60px;
    justify-content: center;
  }
  @media (max-width: 799px) {
    flex-direction: column;
    font-size: 24px;
    gap: 30px;
    margin-top: 0px;
  }
}

.l-see-it-in-action .video-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: calc(50% - 40px);
  @media (min-width: 799px) and (max-width: 1279px) {
    height: unset;
    width: 680px;
  }
  @media (max-width: 799px) {
    width: 90vw;
  }
}

.l-see-it-in-action .video-player-root {
  margin-bottom: 30px;
  object-fit: cover;
  width: 100% !important;
  height: auto !important;
  @media (max-width: 799px) {
    margin-bottom: 15px;
  }
}

.l-see-it-in-action .video-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.l-see-it-in-action .video-title {
  letter-spacing: 0.4px;
  white-space: nowrap;
  @media (min-width: 440px) and (max-width: 799px) {
    font-size: 28px;
  }
  @media (max-width: 440px) {
    font-size: 22px;
  }
}

.l-see-it-in-action .video-description {
  letter-spacing: 0.26px;
  @media (min-width: 440px) and (max-width: 799px) {
    font-size: 24px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
  }
}
