.lrg-meet-the-robots {
  align-items: flex-start;
  background-color: #f6f8f9;
}

.lrg-meet-the-robots .nav-bar-2 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 250px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-130px) translateY(-25px);
  }
}

.lrg-meet-the-robots .place-1 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.lrg-meet-the-robots .angles-right {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.lrg-meet-the-robots .who-we-are-placeholder {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 6px;
  min-height: 29px;
  min-width: 166px;
  text-align: center;
  white-space: nowrap;
}

.lrg-meet-the-robots .span0-2 {
  text-decoration: underline;
}

.lrg-meet-the-robots .mtr-shrinker {
  max-height: none;
  width: 100%;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-height: 1700px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-height: 1540px;
  }
  @media (max-width: 1279px) {
    max-height: 1000px;
  }
  @media (max-width: 799px) {
    display: none;
  }
}

.mtr-shrinker .page-stack {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 92px;
  width: 100%;
  max-width: 1580px;
  z-index: 4;
  height: auto;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
    // margin-left: 0;
    margin-top: 0;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
    // margin-left: 0;
    margin-top: 0;
  }
  @media (max-width: 1279px) {
    max-width: 799px;
    // margin-left: 0;
    margin-top: 0;
  }
}

.mtr-shrinker .overlap-group6 {
  height: 672px;
  margin-right: 58px;
  margin-top: 8px;
  position: relative;
  width: 1434px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.9) translateX(-14px) translateY(40px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.8) translateX(-40px) translateY(-30px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.52) translateX(-600px) translateY(-300px);
  }
}

.mtr-shrinker .group-container {
  height: 648px;
  left: 75px;
  position: absolute;
  top: 24px;
  width: 1359px;
}

.mtr-shrinker .mask-group-5 {
  height: 648px;
  left: 859px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 500px;
}

.mtr-shrinker .group-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 515px;
  position: absolute;
  top: 82px;
  width: 887px;
}

.mtr-shrinker .prototyping {
  letter-spacing: 0.26px;
  line-height: 35px;
  margin-top: -1px;
  min-height: 35px;
  white-space: nowrap;
}

.mtr-shrinker .ns-0the-ns-0-takes {
  letter-spacing: 0.26px;
  line-height: 30px;
  margin-top: 12px;
  min-height: 328px;
  width: 887px;
}

.mtr-shrinker .span0-1 {
  letter-spacing: 0.5px;
}

.mtr-shrinker .net-shape-1 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-top: 25px;
  min-width: 299px;
}

.mtr-shrinker .button-container-1 {
  height: 40px;
  position: relative;
  width: 299px;
}

.mtr-shrinker .title-funky {
  left: 0;
  letter-spacing: 0.4px;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.mtr-shrinker .ns-1 {
  height: 604px;
  margin-top: 12px;
  position: relative;
  width: 1457px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.9) translateX(-62px) translateY(-45px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.8) translateX(-178px) translateY(-170px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.52) translateX(-670px) translateY(-920px);
  }
}

.mtr-shrinker .overlap-group4 {
  height: 604px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1457px;
}

.mtr-shrinker .mask-group-6 {
  height: 604px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 825px;
}

.mtr-shrinker .ns-1the-ns-1-takes {
  left: 803px;
  letter-spacing: 0.26px;
  line-height: 30px;
  position: absolute;
  text-align: right;
  top: 122px;
  width: 654px;
}

.mtr-shrinker .factory-production {
  left: 1286px;
  letter-spacing: 0.26px;
  line-height: 35px;
  position: absolute;
  text-align: right;
  top: 75px;
  white-space: nowrap;
}

.mtr-shrinker .button-container-2 {
  height: 40px;
  left: 1156px;
  position: absolute;
  top: 525px;
  width: 299px;
}

.mtr-shrinker .ns-2 {
  align-self: flex-end;
  height: 562px;
  margin-top: 92px;
  position: relative;
  width: 1536px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.9) translateX(84px) translateY(-90px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.8) translateX(180px) translateY(-240px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.52) translateX(740px) translateY(-1420px);
  }
}

.mtr-shrinker .overlap-group3 {
  height: 562px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1536px;
}

.mtr-shrinker .mask-group-7 {
  height: 562px;
  left: 441px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1095px;
}

.mtr-shrinker .ns-2the-ns-2-takes {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 30px;
  position: absolute;
  top: 93px;
  width: 474px;
}

.mtr-shrinker .in-field-production {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 35px;
  position: absolute;
  text-align: right;
  top: 46px;
  white-space: nowrap;
}

.mtr-shrinker .net-shape-2 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 40px;
  left: 0;
  min-width: 299px;
  position: absolute;
  top: 520px;
  margin-left: 500px;
}
