.button-1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  left: 0;
  min-width: 152px;
  padding: 6px 17px;
  position: absolute;
  top: 0;
}

.the-robots {
  letter-spacing: 0.36px;
  line-height: 20px;
  min-height: 24px;
  min-width: 116px;
  text-align: center;
  white-space: nowrap;
}

.button-1.button-1-1 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.button-1.button-1-1:hover,
.button-1.primary-button-8:hover,
.button-1.primary-button-12:hover {
  opacity: 1;
}

.button-1.primary-button-4,
.button-1.primary-button-10 {
  height: 51px;
  min-width: 179px;
  padding: 11px 7px;
}

.button-1.primary-button-4 .the-robots,
.button-1.primary-button-10 .the-robots,
.button-1.primary-button-8 .the-robots,
.button-1.primary-button-12 .the-robots {
  min-width: 162px;
}

.button-1.primary-button-8,
.button-1.primary-button-12 {
  cursor: pointer;
  height: 51px;
  min-width: 179px;
  opacity: 0;
  padding: 11px 7px;
  transition: all 0.2s ease;
}
