.lrg-resources {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1877px;
  .status-center {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-container-wrapper {
    width: calc(100% - 220px);
    margin-left: 110px;
    margin-top: 20px;
    .tags-container-wrapper {
      position: relative;
      width: 100%;
      min-height: 2em;
      .tags-container {
        position: absolute;
        display: flex;
        flex-flow: row;
        align-items: center;
        right: 0;
        gap: 8px;
        .tag {
          cursor: pointer;
          &.selected {
            font-weight: 700;
            cursor: unset;
          }
        }
        @media (max-width: 440px) {
          height: auto;
          right: unset;
          margin-top: 15px;
          margin-left: 10px;
          position: relative;
          flex-flow: column;
          align-items: start;
          .separator {
            display: none;
          }
        }
      }
    }
    .grid-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .card-wrapper {
        width: 25%;
      }
    }
    @media (min-width: 1439px) and (max-width: 1877px) {
      width: calc(100% - 180px);
      margin-left: 90px;
      margin-top: 20px;
    }
    @media (min-width: 1279px) and (max-width: 1439px) {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-top: 20px;
      .grid-container .card-wrapper {
        width: 33%;
      }
    }
    @media (max-width: 1279px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 20px;
      .grid-container .card-wrapper {
        width: 50%;
      }
      @media (max-width: 799px) {
        .grid-container .card-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.nav-bar-19 {
  align-items: flex-end;
  display: flex;
  margin-left: 120px;
  margin-top: 30px;
  min-width: 186px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin-left: 100px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin-left: 50px;
  }
  @media (max-width: 1279px) {
    margin-left: 30px;
  }
}

.place-8 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.angles-right-7 {
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
  display: flex;
  position: relative;
  top: -5px;
}

.who-we-are-placeholder-5 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 6px;
  min-height: 29px;
  min-width: 102px;
  text-align: center;
  white-space: nowrap;
}

.span0-12 {
  text-decoration: underline;
}
