.l-learn-the-tech {
  align-items: flex-start;
  background-color: #f6f8f9;
}

.l-learn-the-tech .nav-bar-11 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 298px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-130px) translateY(-25px);
  }
}

.l-learn-the-tech .place-12 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.l-learn-the-tech .angles-right-1 {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.l-learn-the-tech .who-we-are-placeholder-1 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 6px;
  min-height: 29px;
  min-width: 214px;
  text-align: center;
  white-space: nowrap;
}

.l-learn-the-tech .span0-4 {
  text-decoration: underline;
}

.l-learn-the-tech .ltr-shrinker {
  max-height: none;
  width: 100%;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-height: 1700px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-height: 1540px;
  }
  @media (max-width: 1279px) {
    max-height: 1080px;
  }
  @media (max-width: 799px) {
    display: none;
  }
}

.l-learn-the-tech .page-stack-1 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 92px;
  width: 1455px;
  z-index: 4;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
    // margin-left: 0;
    margin-top: 0;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
    // margin-left: 0;
    margin-top: 0;
  }
  @media (max-width: 1279px) {
    max-width: 799px;
    // margin-left: 0;
    margin-top: 0;
  }
}

.l-learn-the-tech .title-3 {
  align-self: flex-start;
  letter-spacing: 0.4px;
  line-height: 35px;
  margin-top: 8px;
  min-height: 52px;
  white-space: nowrap;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(1) translateX(65px) translateY(40px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.93) translateX(18px) translateY(10px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.6) translateX(-144px) translateY(-10px);
  }
}

.l-learn-the-tech .one-it-sees {
  height: 514px;
  margin-top: 44px;
  position: relative;
  width: 1401px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.93) translateX(-25px) translateY(40px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.86) translateX(67px) translateY(20px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.535) translateX(553px) translateY(-180px);
  }
}

.l-learn-the-tech .overlap-group4-1 {
  height: 514px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1401px;
}

.l-learn-the-tech .mask-group-5-1 {
  height: 514px;
  left: 612px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 789px;
}

.l-learn-the-tech .it-seesrivelins {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 30px;
  position: absolute;
  top: 117px;
  width: 614px;
}

.l-learn-the-tech .span0-3 {
  letter-spacing: 0.5px;
}
.l-learn-the-tech .one {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 35px;
  position: absolute;
  top: 70px;
  white-space: nowrap;
}

.l-learn-the-tech .two-it-adapts {
  align-items: flex-start;
  display: flex;
  margin-right: 12px;
  margin-top: 75px;
  min-width: 1389px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.93) translateX(-35px) translateY(35px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.86) translateX(54px) translateY(-40px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.535) translateX(550px) translateY(-635px);
  }
}

.l-learn-the-tech .overlap-group3-1 {
  height: 604px;
  position: relative;
  width: 1389px;
}

.l-learn-the-tech .overlap-group2 {
  height: 604px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1389px;
}

.l-learn-the-tech .mask-group-6-1 {
  height: 604px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 747px;
}

.l-learn-the-tech .it-adaptsrivelin {
  left: 755px;
  letter-spacing: 0.26px;
  line-height: 30px;
  position: absolute;
  text-align: right;
  top: 173px;
  width: 634px;
}

.l-learn-the-tech .two {
  left: 1331px;
  letter-spacing: 0.26px;
  line-height: 35px;
  position: absolute;
  text-align: right;
  top: 125px;
  white-space: nowrap;
}

.l-learn-the-tech .three-it-understands {
  align-items: flex-start;
  display: flex;
  margin-top: 89px;
  min-width: 1401px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.93) translateX(-23px) translateY(45px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.86) translateX(67px) translateY(-90px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.535) translateX(560px) translateY(-1085px);
  }
}

.l-learn-the-tech .overlap-group1-8 {
  height: 562px;
  position: relative;
  width: 1401px;
}

.l-learn-the-tech .overlap-group-14 {
  height: 562px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1401px;
}

.l-learn-the-tech .mask-group-7-1 {
  height: 562px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 855px;
}

.l-learn-the-tech .it-finishesthe-ri {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 30px;
  position: absolute;
  top: 120px;
  width: 548px;
}
.l-learn-the-tech .three {
  left: 0;
  letter-spacing: 0.26px;
  line-height: 35px;
  position: absolute;
  text-align: right;
  top: 70px;
  white-space: nowrap;
}
