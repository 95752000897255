.mwwa-container {
  display: none !important;
  align-items: flex-start;
  height: 7169px;
  @media (max-width: 670px) {
    height: 6273px;
  }
  @media (max-width: 585px) {
    height: 5377px;
  }
  @media (max-width: 500px) {
    height: 4481px;
  }
  @media (max-width: 420px) {
    height: 3585px;
  }
  @media (max-width: 345px) {
    height: 3137px;
  }
  @media (max-width: 799px) {
    display: flex !important;
  }
}

.google-pixel-6-pro-who-we-are {
  align-items: flex-end;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  height: 4441px;
  overflow: hidden;
  padding: 32px 0;
  position: relative;
  width: 412px;
  transform: translateY(-50%) scale(1.6) translateY(50%);
  @media (max-width: 670px) {
    transform: translateY(-50%) scale(1.4) translateY(50%);
  }
  @media (max-width: 585px) {
    transform: translateY(-50%) scale(1.2) translateY(50%);
  }
  @media (max-width: 500px) {
    transform: unset;
  }
  @media (max-width: 420px) {
    transform: translateY(-50%) scale(0.8) translateY(50%);
  }
  @media (max-width: 345px) {
    transform: translateY(-50%) scale(0.7) translateY(50%);
  }
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-normal-onyx-18px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-normal-onyx-29px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 29px;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-normal-onyx-29px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-normal-onyx-21px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-onyx-24px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-bunker-12px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-white-12px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-bunker-27px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-bunker-18px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-normal-bunker-18px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.google-pixel-6-pro-who-we-are .atkinsonhyperlegible-bold-bunker-35px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.google-pixel-6-pro-who-we-are .mwwa-overlap-group6 {
  height: 988px;
  margin-right: 3px;
  position: relative;
  width: 387px;
}

.google-pixel-6-pro-who-we-are .page-stack {
  display: flex;
  flex-direction: column;
  height: 988px;
  width: 387px;
}

.google-pixel-6-pro-who-we-are .title {
  height: 66px;
  letter-spacing: 0.54px;
  line-height: 30px;
  margin-top: -1.5px;
  width: 376px;
}

.google-pixel-6-pro-who-we-are .we-are-rivelinwe-a {
  height: 174px;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 11px;
  margin-top: 15.6px;
  width: 376px;
}

.google-pixel-6-pro-who-we-are .pastrivelin-roboti {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 645px;
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 11px;
  margin-top: 87px;
  width: 376px;
}

.google-pixel-6-pro-who-we-are .group-36 {
  align-items: flex-start;
  display: flex;
  height: 38px;
  justify-content: flex-end;
  left: 0px;
  min-width: 263px;
  position: absolute;
  top: 282px;
}

.google-pixel-6-pro-who-we-are .button-container {
  height: 38px;
  margin-top: 0;
  position: relative;
  width: 113px;
}

.google-pixel-6-pro-who-we-are .primary-button-container {
  height: 38px;
  margin-left: 6px;
  margin-top: 0;
  position: relative;
  width: 132px;
}

.google-pixel-6-pro-who-we-are .mask-group-8 {
  height: 668px;
  margin-right: -7px;
  margin-top: 8px;
  object-fit: cover;
  width: 426px;
}

.google-pixel-6-pro-who-we-are .mwwa-in-a-system-where-co {
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 3px;
  margin-top: 28px;
  min-height: 199px;
  width: 376px;
}

.google-pixel-6-pro-who-we-are .mwwa-team-rb {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-top: 39px;
  min-height: 559px;
  width: 378px;
}

.google-pixel-6-pro-who-we-are .overlap-group {
  background-position: 50% 50%;
  background-size: cover;
  height: 477px;
  margin-left: 0;
  position: relative;
  width: 378px;
}

.google-pixel-6-pro-who-we-are ._nobkg {
  height: 438px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 364px;
}

.google-pixel-6-pro-who-we-are .icon-linkedin {
  cursor: pointer;
  height: 38px;
  left: 322px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 38px;
}

.google-pixel-6-pro-who-we-are .name {
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 18px;
  min-height: 67px;
  white-space: nowrap;
}

.google-pixel-6-pro-who-we-are .mwwa-team-da {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-top: 24px;
  min-height: 559px;
  width: 378px;
}

.google-pixel-6-pro-who-we-are .mwwa-team-dm {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 4.31px;
  margin-top: 24px;
  min-height: 567px;
  width: 403px;
}

.google-pixel-6-pro-who-we-are .overlap-group4 {
  height: 485px;
  margin-left: 0;
  position: relative;
  width: 403px;
}

.google-pixel-6-pro-who-we-are .tim-hufner-3-wcnx-ptrr50-unsplash-3 {
  height: 476px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 377px;
}

.google-pixel-6-pro-who-we-are .david-mason_nobkg1 {
  height: 485px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 403px;
}

.google-pixel-6-pro-who-we-are .icon-linkedin-1 {
  cursor: pointer;
  height: 38px;
  left: 334px;
  object-fit: cover;
  position: absolute;
  top: 23px;
  width: 38px;
}

.google-pixel-6-pro-who-we-are .name-1 {
  letter-spacing: 0;
  line-height: 31px;
  margin-left: 12.95px;
  margin-top: 18px;
  min-height: 67px;
  white-space: nowrap;
}

.google-pixel-6-pro-who-we-are .mwwa-team-ih {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 26px;
  min-height: 558px;
  width: 377px;
}

.google-pixel-6-pro-who-we-are .overlap-group5 {
  background-position: 50% 50%;
  background-size: cover;
  height: 476px;
  margin-left: 0;
  position: relative;
  width: 377px;
}

.google-pixel-6-pro-who-we-are .ianhalliday_nobkg1 {
  height: 437px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 363px;
}

.google-pixel-6-pro-who-we-are .icon-linkedin-2 {
  cursor: pointer;
  height: 38px;
  left: 321px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 38px;
}
