.resource-page {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1877px;
  .article-body {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
    width: calc(100% - 220px);
    margin-left: 110px;
    margin-top: 60px;
    @media (min-width: 1439px) and (max-width: 1877px) {
      width: calc(100% - 180px);
      margin-left: 90px;
      margin-top: 50px;
    }
    @media (min-width: 1279px) and (max-width: 1439px) {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-top: 30px;
    }
    @media (min-width: 440px) and (max-width: 1279px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 20px;
    }
    @media (max-width: 440px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 20px;
      p,
      strong,
      em,
      ol,
      li,
      ul {
        font-size: 16px;
        line-height: 1.3em;
      }
      .atkinsonhyperlegible-bold-bunker-46px {
        font-size: 26px;
      }
    }
    .atkinsonhyperlegible-bold-bunker-46px {
      line-height: 1.2em;
    }
    .small {
      margin-bottom: 25px;
    }
    blockquote {
      display: block;
      border-left: #309e56 4px solid;
      padding-block-start: 0.5em;
      padding-block-end: 0.5em;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      padding-inline-start: 40px;
      padding-inline-end: 40px;
      font-style: italic;
      color: #309e56;
    }
    em {
      font-style: italic;
    }
    strong {
      font-weight: bold;
    }
    img {
      width: 100%;
      margin: 40px 0;
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 0.6em;
    }
    ol {
      list-style: decimal;
      margin-bottom: 1em;
      li {
        margin-left: 30px;
      }
    }
  }
}

.nav-bar-42 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 302px;
  z-index: 4;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: translateX(-50%) scale(0.94) translateX(calc(50% + 10px));
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: translateX(-50%) scale(0.88) translateX(calc(50% - 45px)) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: translateX(-50%) scale(0.74) translateX(calc(50% - 80px)) translateY(-25px);
  }
}
