.google-pixel-6-pro .m-button-1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 24px;
  left: 0;
  min-width: 90px;
  padding: 2.6px 11px;
  position: absolute;
  top: 0;
}

.m-button-1 .the-robots-1 {
  letter-spacing: 0.2px;
  line-height: 11px;
  min-height: 15px;
  min-width: 66px;
  text-align: center;
  white-space: nowrap;
}

.m-button-1 .m-button-1.button-1-1 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.m-button-1 .m-button-1.button-1-1:hover {
  opacity: 1;
}
