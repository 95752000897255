.mwwa-ns-1 {
  display: flex;
  height: 132px;
  margin-right: -38px;
  margin-top: 9px;
  width: 417px;
}

.mwwa-reimagine-a-world-wi {
  height: 134px;
  letter-spacing: 0.35px;
  line-height: 35px;
  width: 417px;
}

.mwwa-span0 {
  letter-spacing: 0.18px;
}
