.mmtr-container {
  display: none !important;
  align-items: flex-start;
  height: 4000px;
  @media (max-width: 799px) {
    display: flex !important;
  }
  @media (max-width: 670px) {
    height: 3500px;
  }
  @media (max-width: 585px) {
    height: 3000px;
  }
  @media (max-width: 500px) {
    height: 2500px;
  }
  @media (max-width: 420px) {
    height: 2000px;
  }
  @media (max-width: 345px) {
    height: 1740px;
  }
}

.google-pixel-6-pro-meet-the-robots {
  align-items: flex-start;
  background-color: #f6f8f9;
  flex-direction: column;
  overflow: hidden;
  padding: 25.5px 0;
  width: 412px;
  display: flex;
  transform: translateY(-50%) scale(1.6) translateY(50%);
  @media (max-width: 670px) {
    transform: translateY(-50%) scale(1.4) translateY(50%);
  }
  @media (max-width: 585px) {
    transform: translateY(-50%) scale(1.2) translateY(50%);
  }
  @media (max-width: 500px) {
    transform: unset;
  }
  @media (max-width: 420px) {
    transform: translateY(-50%) scale(0.8) translateY(50%);
  }
  @media (max-width: 345px) {
    transform: translateY(-50%) scale(0.7) translateY(50%);
  }
}

.google-pixel-6-pro-meet-the-robots .title {
  align-self: center;
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.54px;
  line-height: 30px;
  margin-right: 4px;
  min-height: 66px;
  width: 364px;
}

.google-pixel-6-pro-meet-the-robots .ns-0 {
  align-items: flex-end;
  display: flex;
  margin-left: -22.55px;
  margin-top: 24px;
  min-width: 413px;
}

.google-pixel-6-pro-meet-the-robots .group-container {
  height: 842px;
  margin-bottom: 0;
  position: relative;
  width: 415px;
}

.google-pixel-6-pro-meet-the-robots .mask-group-5 {
  height: 406px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 313px;
}

.google-pixel-6-pro-meet-the-robots .group-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 56px;
  min-height: 732px;
  position: absolute;
  top: 0;
  width: 360px;
}

.google-pixel-6-pro-meet-the-robots .prototypingns-0 {
  -webkit-text-stroke: 1px transparent;
  letter-spacing: 0.35px;
  line-height: 35px;
  margin-left: 0.79px;
  margin-top: -4px;
  min-height: 64px;
  white-space: nowrap;
}

.google-pixel-6-pro-meet-the-robots .span0 {
  letter-spacing: 0.18px;
}

.google-pixel-6-pro-meet-the-robots .the-ns-0-takes-you-f {
  -webkit-text-stroke: 1px transparent;
  letter-spacing: 0.16px;
  line-height: 21px;
  margin-left: 0.79px;
  margin-top: 344px;
  min-height: 274px;
  width: 359px;
}

.google-pixel-6-pro-meet-the-robots .span0-1 {
  letter-spacing: 0.35px;
}

.google-pixel-6-pro-meet-the-robots .b-net-shape {
  align-items: flex-start;
  display: flex;
  margin-top: 26px;
  min-width: 209px;
}

.google-pixel-6-pro-meet-the-robots .button-container {
  height: 28px;
  position: relative;
  width: 209px;
}

.google-pixel-6-pro-meet-the-robots .ns-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: -27.33px;
  margin-top: 27px;
  width: 463px;
}

.google-pixel-6-pro-meet-the-robots .overlap-group4 {
  height: 650px;
  margin-top: -4px;
  position: relative;
  width: 463px;
}

.google-pixel-6-pro-meet-the-robots .mask-group-6 {
  height: 339px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 463px;
}

.google-pixel-6-pro-meet-the-robots .factory-productionns-1 {
  left: 60px;
  letter-spacing: 0.35px;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.google-pixel-6-pro-meet-the-robots .the-ns-1-takes-you-i {
  left: 60px;
  letter-spacing: 0.16px;
  line-height: 21px;
  position: absolute;
  top: 373px;
  width: 373px;
}

.google-pixel-6-pro-meet-the-robots .button-container-1 {
  height: 28px;
  margin-left: 60.33px;
  margin-top: 28px;
  position: relative;
  width: 209px;
}

.google-pixel-6-pro-meet-the-robots .ns-2 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: -327px;
  margin-top: 32px;
  width: 819px;
}

.google-pixel-6-pro-meet-the-robots .overlap-group3 {
  height: 700px;
  margin-top: 15px;
  position: relative;
  width: 819px;
}

.google-pixel-6-pro-meet-the-robots .mask-group-7 {
  height: 420px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 819px;
}

.google-pixel-6-pro-meet-the-robots .the-ns-2-takes-you-f {
  left: 113px;
  letter-spacing: 0.16px;
  line-height: 21px;
  position: absolute;
  top: 386px;
  width: 375px;
}

.google-pixel-6-pro-meet-the-robots .in-field-productionns-2 {
  left: 113px;
  letter-spacing: 0.35px;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.google-pixel-6-pro-meet-the-robots .button-container-2 {
  height: 28px;
  margin-left: 113px;
  margin-top: 8px;
  position: relative;
  width: 209px;
}
