.hero-large-desktop2 {
  height: 419px;
  max-width: 1877px;
  z-index: 2;
  position: relative;
  width: 100%;
  @media (min-width: 1279px) and (max-width: 1439px) {
    height: 383px;
  }
  @media (max-width: 1279px) {
    height: 283px;
  }
  @media (max-width: 799px) {
    height: 150px;
  }
}

.hero-large-desktop2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 1279px) {
    object-position: 74%;
  }
}

.title-1 {
  height: 81px;
  width: 238px;
  left: 120px;
  bottom: calc(28% - 23px);
  position: absolute;
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-30%);
    left: 60px;
  }
  @media (max-width: 420px) {
    left: 70px;
  }
}

.title-2 {
  width: 238px;
  bottom: calc(28% - 23px);
  position: absolute;
}

.hero-large-desktop2.hero-large-desktop2-1 .title-1,
.hero-large-desktop2.hero-large-desktop2-1 .title-2 {
  width: 336px;
}

.hero-large-desktop2.hero-large-desktop2-2 .title-1,
.hero-large-desktop2.hero-large-desktop2-2 .title-2 {
  width: 374px;
  @media (max-width: 500px) {
    width: 220px;
    text-align: left;
    height: 97px;
    left: 30px;
  }
}

.hero-large-desktop2.hero-large-desktop2-3 {
  background-color: #000000;
}

.hero-large-desktop2.hero-large-desktop2-3 .title-1,
.hero-large-desktop2.hero-large-desktop2-3 .title-2 {
  width: 416px;
  left: 615px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    left: 420px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    left: 350px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    left: 250px;
  }
  @media (max-width: 799px) {
    left: 180px;
  }
  @media (max-width: 590px) {
    left: 50px;
  }
  @media (max-width: 355px) {
    width: 250px;
    left: 30px;
  }
}

.hero-large-desktop2.hero-large-desktop2-4 .title-1,
.hero-large-desktop2.hero-large-desktop2-4 .title-2 {
  width: 418px;
  @media (max-width: 500px) {
    width: 280px;
    text-align: left;
    height: 100px;
  }
}

.hero-large-desktop2.hero-large-desktop2-5 .title-1,
.hero-large-desktop2.hero-large-desktop2-5 .title-2 {
  width: 526px;
  @media (min-width: 799px) and (max-width: 1279px) {
    left: 50px;
    height: 41px;
  }
  @media (max-width: 799px) {
    left: 41px;
    height: 61px;
  }

  @media (max-width: 500px) {
    width: 520px;
    text-align: left;
    height: 100px;
  }
}

.hero-large-desktop2.hero-large-desktop2-6 .title-1,
.hero-large-desktop2.hero-large-desktop2-6 .title-2 {
  width: 672px;
  @media (max-width: 1279px) {
    left: 50px;
    width: 590px;
    height: 100px;
  }
  @media (max-width: 530px) {
    width: 590px;
    height: 100px;
    text-align: left;
  }
}

.hero-large-desktop2.hero-large-desktop2-7 .title-1,
.hero-large-desktop2.hero-large-desktop2-7 .title-2 {
  width: 496px;
}

.hero-large-desktop2.hero-large-desktop2-8 .title-1,
.hero-large-desktop2.hero-large-desktop2-8 .title-2 {
  width: 320px;
}

.hero-large-desktop2.hero-large-desktop-see-it-in-action .title-1,
.hero-large-desktop2.hero-large-desktop-see-it-in-action .title-2 {
  width: 500px;
  left: 60px;
  @media (min-width: 799px) and (max-width: 1279px) {
    left: 45px;
    height: 45px;
  }
  @media (max-width: 799px) {
    left: 45px;
  }
  @media (max-width: 500px) {
    width: 480px;
    text-align: left;
    height: 100px;
  }
}
