.button-3 {
  align-items: flex-end;
  background-color: #309e56;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  height: 54px;
  justify-content: flex-end;
  left: 0;
  min-width: 207px;
  padding: 5.9px 12.4px;
  position: absolute;
  top: 0;
}

.button-open-positions {
  letter-spacing: 0.4px;
  line-height: 12px;
  min-height: 27px;
  min-width: 180px;
  text-align: center;
  white-space: nowrap;
}

.button-3.primary-button-24 {
  box-shadow: inset 5px 7px 6px #00000029;
  opacity: 0;
  padding: 6.4px 12.4px;
  transition: all 0.2s ease;
}

.button-3.primary-button-24:hover {
  opacity: 1;
}

.button-3.primary-button-24 .button-open-positions {
  line-height: 13px;
}
