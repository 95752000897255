.lrg-request-a-demo {
  background-color: #f6f8f9;
  width: 100vw;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (max-width: 1279px) {
    max-width: 799px;
  }
}

.form-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 532px;
  position: relative;
  margin-left: 226px;
  width: calc(100% - 452px);
  z-index: 3;
  @media (min-width: 1279px) and (max-width: 1877px) {
    margin-left: 100px;
    width: calc(100% - 200px);
  }
  @media (max-width: 1279px) {
    margin-left: 80px;
    width: calc(100% - 160px);
  }
  @media (max-width: 799px) {
    margin-left: 15px;
    width: calc(100% - 30px);
  }
}

.first-name-2 {
  align-items: flex-end;
  display: flex;
  height: 91px;
  min-width: calc(100% + 1px);
}

.overlap-group-23 {
  height: 84px;
  position: relative;
  width: calc(100% + 3px);
}

.field-3 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 55px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
}

.st-name {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.x-5 {
  background-color: transparent;
  border: 0;
  height: 37px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 1416px;
}

.x-5::placeholder,
.x-6::placeholder {
  color: #34363899;
}

.last-name-2 {
  align-items: flex-end;
  display: flex;
  height: 91px;
  margin-top: 20px;
  min-width: calc(100% + 1px);
}

.overlap-group-24 {
  height: 84px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.x-6 {
  background-color: transparent;
  border: 0;
  height: 39px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 1416px;
}

.submit-3 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-left: 0;
  margin-top: 14px;
  min-width: 171px;
  @media (max-width: 799px) {
    margin-top: 39px;
  }
}

.button-container-3 {
  border: none;
  border-radius: 8px;
  height: 56px;
  position: relative;
  width: 171px;
}

.form-1-item {
  align-items: flex-end;
  display: flex;
  height: 91px;
  margin-top: 20px;
  min-width: calc(100% + 1px);
}

.overlap-group-25 {
  height: 84px;
  position: relative;
  width: calc(100% + 3px);
}

.overlap-group-26 {
  height: 84px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.field-4 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 55px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
}

.email-1,
.company-name-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.x-7,
.x-8 {
  background-color: transparent;
  border: 0;
  height: 38px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 1416px;
}

.x-7::placeholder,
.x-8::placeholder {
  color: #34363899;
}

.submit-success,
.submit-failure {
  margin-top: 15px;
}
