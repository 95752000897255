.lrg-faq {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1877px;
}

.nav-bar-20 {
  align-items: flex-end;
  display: flex;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 186px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin-left: 100px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin-left: 50px;
  }
  @media (max-width: 1279px) {
    margin-left: 30px;
  }
}

.place-9 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.angles-right-8 {
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
  display: flex;
  position: relative;
  top: -5px;
}

.who-we-are-placeholder-6 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -5.9px;
  margin-left: 6px;
  min-height: 29px;
  text-align: center;
  white-space: nowrap;
}

.span0-13 {
  text-decoration: underline;
}

.adobe-dc-container {
  width: 100%;
  margin-top: 40px;
}
