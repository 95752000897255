.atkinsonhyperlegible-super {
  position: relative;
  top: -0.5em;
  font-size: 70% !important;
}

.atkinsonhyperlegible-bold-white-10px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-white-22px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-white-26px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-white-36px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-white-26px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
}

.atkinsonhyperlegible-normal-white-20px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-white-23px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-onyx-22px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-white-16px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-white-17px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-white-20px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-onyx-26px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-onyx-18px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-white-22px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-onyx-18px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-sonic-silver-15px {
  color: #727272;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-onyx-24px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-onyx-14px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-white-65px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-bunker-26px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-white-18px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-onyx-22px-2,
.atkinsonhyperlegible-bold-onyx-22px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
}

.atkinsonhyperlegible-bold-onyx-32px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-rose-26px {
  color: #ff4d4d;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
}

.atkinsonhyperlegible-normal-rose-26px-2 {
  color: #ff4d4d;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-onyx-24px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-onyx-36px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 36px;
}

.atkinsonhyperlegible-normal-onyx-36px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-bunker-36px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-bunker-40px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-bunker-46px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-bunker-50px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-bunker-18px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-bunker-20px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-onyx-20px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
}

.atkinsonhyperlegible-bold-onyx-20px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-onyx-18px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-onyx-16px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-onyx-35px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 35px;
}

.atkinsonhyperlegible-bold-black-12px {
  color: #000000;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-bold-black-18px {
  color: #000000;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.fontawesome6freesolid-normal-sea-green-18px {
  color: #309e56;
  font-family: 'Font Awesome 6 Free Solid', Helvetica;
  font-size: 18px;
}

.fontawesome6freesolid-solid-normal-sea-green-18px {
  color: #309e56;
  font-family: 'FontAwesome 6 Free Solid-Solid', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-sea-green-18px {
  color: #309e56;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-sea-green-28px {
  color: #309e56;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-gallery-24px {
  color: #ebeded;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-gallery-32px {
  color: #ebeded;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-onyx-26px,
.atkinsonhyperlegible-normal-onyx-26px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
}

.atkinsonhyperlegible-bold-onyx-46px {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-white-22px-2 {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
}

.atkinsonhyperlegible-normal-white-34px {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-bold-onyx-26px-2 {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

.atkinsonhyperlegible-normal-bunker-18px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.atkinsonhyperlegible-normal-bunker-24px {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.border-2px-gallery {
  border: 2px solid #ebeded;
}

.border-1px-dove-gray {
  border: 1px solid #707070;
}

.border-2px-white {
  border: 2px solid #ffffff;
}
