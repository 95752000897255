.lrg-privacy-policy {
  background-color: #f6f8f9;
  justify-content: flex-end;
}

.website-privacy-poli {
  flex: 1;
  letter-spacing: 0.09px;
  line-height: 40px;
  margin: 31px 71px 0 71px;
  z-index: 2;
  @media (max-width: 799px) {
    line-height: 34px;
    margin: 31px 30px 0 30px;
  }
}

.span0-4 {
  letter-spacing: 0.13px;
}
