.github {
  align-self: flex-end;
  background-image: url(../../static/img/github-1@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 24px;
  margin-bottom: 0;
  margin-left: 18px;
  width: 25px;
}
