.mltt-container {
  display: none !important;
  align-items: flex-start;
  height: 3300px;
  @media (max-width: 670px) {
    height: 2950px;
  }
  @media (max-width: 585px) {
    height: 2480px;
  }
  @media (max-width: 500px) {
    height: 2060px;
  }
  @media (max-width: 420px) {
    height: 1670px;
  }
  @media (max-width: 345px) {
    height: 1470px;
  }
  @media (max-width: 799px) {
    display: flex !important;
  }
}

.google-pixel-6-pro-learn-the-tech {
  align-items: flex-end;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  height: 2219px;
  overflow: hidden;
  padding: 30.5px 0;
  position: relative;
  width: 412px;
  transform: translateY(-50%) scale(1.6) translateY(50%);
  @media (max-width: 670px) {
    transform: translateY(-50%) scale(1.4) translateY(50%);
  }
  @media (max-width: 585px) {
    transform: translateY(-50%) scale(1.2) translateY(50%);
  }
  @media (max-width: 500px) {
    transform: unset;
  }
  @media (max-width: 420px) {
    transform: translateY(-50%) scale(0.8) translateY(50%);
  }
  @media (max-width: 345px) {
    transform: translateY(-50%) scale(0.7) translateY(50%);
  }
}

.google-pixel-6-pro-learn-the-tech {
  .atkinsonhyperlegible-bold-bunker-18px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 18px;
  }
  .atkinsonhyperlegible-normal-bunker-18px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .atkinsonhyperlegible-bold-bunker-35px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
  }
  .atkinsonhyperlegible-normal-onyx-18px {
    color: #343638;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .atkinsonhyperlegible-bold-bunker-27px {
    color: #121213;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
  }
}

.google-pixel-6-pro-learn-the-tech .title {
  align-self: center;
  letter-spacing: 0.54px;
  line-height: 30px;
  margin-left: 8px;
  min-height: 36px;
  white-space: nowrap;
  width: 376px;
}

.google-pixel-6-pro-learn-the-tech .ns-0 {
  align-self: flex-start;
  display: flex;
  height: 62px;
  margin-left: 33.79px;
  margin-top: 24px;
  width: 130px;
}

.google-pixel-6-pro-learn-the-tech .group-35 {
  display: flex;
  flex: 1;
  margin-bottom: 0;
  margin-top: 0;
  width: 132px;
}

.google-pixel-6-pro-learn-the-tech .oneit-sees {
  -webkit-text-stroke: 1px transparent;
  height: 64px;
  letter-spacing: 0.35px;
  line-height: 35px;
  margin-top: -4px;
  white-space: nowrap;
  width: 132px;
}

.google-pixel-6-pro-learn-the-tech .span0 {
  letter-spacing: 0.18px;
}

.google-pixel-6-pro-learn-the-tech .rivelins-system-sca {
  align-self: center;
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  //font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 19px;
  margin-top: 16px;
  min-height: 119px;
  width: 365px;
}

.google-pixel-6-pro-learn-the-tech .mask-group-5 {
  height: 354px;
  margin-right: -4.84px;
  margin-top: 29px;
  object-fit: cover;
  width: 422px;
}

.google-pixel-6-pro-learn-the-tech .rivelins-system-rec {
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 3px;
  margin-top: 21px;
  min-height: 149px;
  width: 376px;
}

.google-pixel-6-pro-learn-the-tech .mask-group-6 {
  height: 383px;
  margin-top: 19px;
  object-fit: cover;
  width: 412px;
}

.google-pixel-6-pro-learn-the-tech .the-rivelin-system-u {
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 3px;
  margin-top: 21px;
  min-height: 174px;
  width: 376px;
}

.google-pixel-6-pro-learn-the-tech .mask-group-7 {
  height: 332px;
  margin-top: 56px;
  object-fit: cover;
  width: 412px;
}
