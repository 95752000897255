.lrg-contact-us {
  align-items: center;
  background-color: #f6f8f9;
}

.cu-shrinker {
  height: auto;
}
.flex-row-6 {
  align-items: flex-start;
  display: flex;
  margin-top: 35px;
  z-index: 3;
  @media (max-width: 1279px) {
    flex-flow: column;
    align-items: center;
  }
  @media (max-width: 799px) {
    margin-top: 20px;
  }
}
.form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 871px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    width: 620px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    width: 540px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    width: 680px;
  }
  @media (max-width: 799px) {
    width: 90vw;
  }
}

.first-name {
  align-items: flex-end;
  display: flex;
  height: 91px;
  min-width: 100%;
}

.overlap-group-15 {
  height: 84px;
  position: relative;
  width: 100%;
}

.field {
  background-color: #ffffff;
  border-radius: 6px;
  height: 55px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
  @media (max-width: 799px) {
    height: 40px;
  }
}

.first-name-1,
.last-name-1,
.message-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  @media (max-width: 799px) {
    font-size: 20px;
  }
}

.x {
  background-color: transparent;
  border: 0;
  height: 37px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: calc(100% - 24px);
  @media (max-width: 799px) {
    top: 34px;
    height: 32px;
    font-size: 16px;
  }
}

.x::placeholder,
.x-1::placeholder,
.x-2::placeholder {
  color: #34363899;
}

.last-name {
  align-items: flex-end;
  display: flex;
  height: 91px;
  margin-top: 20px;
  min-width: 100%;
  @media (max-width: 799px) {
    margin-top: 0;
  }
}

.x-1 {
  background-color: transparent;
  border: 0;
  height: 39px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: calc(100% - 24px);
  @media (max-width: 799px) {
    top: 34px;
    height: 32px;
    font-size: 16px;
  }
}

.message {
  align-items: flex-end;
  display: flex;
  height: 154px;
  margin-top: 20px;
  min-width: 100%;
  @media (max-width: 799px) {
    margin-top: 0;
    height: 84px;
  }
}

.message span {
  @media (max-width: 799px) {
    font-size: 20px;
  }
}

.overlap-group3-1 {
  height: 148px;
  position: relative;
  width: 100%;
  @media (max-width: 799px) {
    height: 84px;
  }
}

.field-1 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 118px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
  @media (max-width: 799px) {
    height: 40px;
  }
}

.x-2 {
  background-color: transparent;
  border: 0;
  height: 103px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 36px;
  width: calc(100% - 24px);
  @media (max-width: 799px) {
    top: 34px;
    height: 32px;
    font-size: 16px;
  }
}

.submit {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-top: 70px;
  min-width: 171px;
  @media (max-width: 799px) {
    margin-top: 20px;
  }
}

.button-container-2 {
  border: none;
  border-radius: 8px;
  height: 56px;
  position: relative;
  width: 171px;
  background-color: transparent;
  .button-hover {
    @media (max-width: 799px) {
      width: 171px;
      height: 45px;
    }
  }
  .submit-1 {
    @media (max-width: 799px) {
      font-size: 18px;
    }
  }
}

.map {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  height: 744px;
  margin-left: 134px;
  width: 500px;
  iframe {
    width: 100%;
    height: 100%;
  }
  .atkinsonhyperlegible-normal-onyx-26px {
    @media (max-width: 799px) {
      font-size: 20px;
      line-height: 1.5;
    }
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    margin-top: 80px;
    align-self: unset;
    margin-left: 0;
    width: 680px;
    height: 820px;
  }
  @media (max-width: 799px) {
    margin-top: 20px;
    margin-left: 0;
    width: 90vw;
    height: 150vw;
  }
}

.rivelin-roboticsuni {
  height: 162px;
  letter-spacing: 0;
  line-height: 35px;
  margin-top: -4.5px;
  width: 323px;
}

.rectangle-459 {
  background-color: #ffffff;
  flex: 1;
  margin-top: 20.5px;
  @media (max-width: 799px) {
    margin-top: 10.5px;
  }
}

.form-item {
  align-items: flex-end;
  display: flex;
  height: 91px;
  margin-top: 20px;
  min-width: 100%;
  @media (max-width: 799px) {
    margin-top: 0;
  }
}

.field-2 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 55px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
  @media (max-width: 799px) {
    height: 40px;
  }
}

.email,
.company-name {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.email span {
  @media (max-width: 799px) {
    font-size: 20px;
  }
}

.x-3,
.x-4 {
  background-color: transparent;
  border: 0;
  height: 38px;
  left: 12px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
  position: absolute;
  top: 40px;
  width: calc(100% - 24px);
  @media (max-width: 799px) {
    top: 34px;
    height: 32px;
    font-size: 16px;
  }
}

.x-3::placeholder,
.x-4::placeholder {
  color: #34363899;
}

.submit-success,
.submit-failure {
  margin-top: 15px;
}
