.hero-large-desktop {
  background-position: 50% 100%;
  background-size: cover;
  display: flex;
  width: calc(100% - 140px);
  flex-direction: column;
  margin-left: 92px;
  margin-top: 70px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    width: calc(100% - 120px);
    margin-left: 60px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    width: calc(100% - 100px);
    margin-left: 50px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    width: calc(100% - 100px);
    margin-left: 50px;
  }
  @media (max-width: 799px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
}

.hero-large-desktop .title {
  width: 100%;
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  letter-spacing: 1.04px;
  line-height: 80px;
  word-wrap: break-word;
  @media (min-width: 1439px) and (max-width: 1877px) {
    font-size: 36px;
    line-height: 60px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    font-size: 32px;
    line-height: 50px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (min-width: 440px) and (max-width: 799px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.hero-large-desktop .rivelins-net-shape-r {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 23px;
  font-weight: 400;
  height: 128px;
  letter-spacing: 0.96px;
  line-height: 36px;
  margin-top: 28px;
  @media (min-width: 799px) and (max-width: 1279px) {
    text-align: left;
    margin-top: 0;
    margin-right: 200px;
    margin-left: 13px;
  }
}
.hero-large-desktop .sub-title {
  width: 100%;
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.96px;
  line-height: 28px;
  margin-top: 25px;
  margin-right: 600px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    font-size: 20px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    font-size: 18px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    font-size: 18px;
  }
  @media (min-width: 440px) and (max-width: 799px) {
    margin-top: 12px;
    font-size: 16px;
  }
  @media (max-width: 440px) {
    font-size: 16px;
    line-height: 22px;
  }
}
