.mmtr-button-0-2 {
  align-items: flex-start;
  display: flex;
  height: 28px;
  left: 0;
  min-width: 209px;
  position: absolute;
  top: 0;
}

.mmtr-button-0-2 .overlap-group {
  height: 28px;
  position: relative;
  width: 209px;
}

.mmtr-rectangle-450 {
  border: 2px solid;
  border-color: #000000;
  border-radius: 60px;
  height: 209px;
  left: 90px;
  position: absolute;
  top: -90px;
  transform: rotate(-90deg);
  width: 28px;
}

.connect-with-an-engineer {
  -webkit-text-stroke: 1px transparent;
  left: 14px;
  letter-spacing: 0.24px;
  line-height: 13px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.mmtr-button-0-2.button-0-1,
.mmtr-button-0-2.button-0-1-1,
.mmtr-button-0-2.button-0-1-2 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.mmtr-button-0-2.button-0-1-1:hover,
.mmtr-button-0-2.button-0-1-2:hover,
.mmtr-button-0-2.button-0-1:hover {
  opacity: 1;
}

.mmtr-button-0-2.button-0-1-1 .mmtr-rectangle-450,
.mmtr-button-0-2.button-0-1-2 .mmtr-rectangle-450,
.mmtr-button-0-2.button-0-1 .mmtr-rectangle-450 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
}

.mmtr-button-0-2.mmtr-button-0-3 .connect-with-an-engineer,
.mmtr-button-0-2.button-0-1-2 .connect-with-an-engineer {
  -webkit-text-stroke: unset;
}

.mmtr-button-0-2.button-0 .connect-with-an-engineer,
.mmtr-button-0-2.button-0-1 .connect-with-an-engineer {
  -webkit-text-stroke: unset;
}
