.dropdown-wrapper {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0;
  margin-left: 44px;
  min-width: 310px;
  pointer-events: none;
  position: relative;
}

.dropdown-wrapper .overlap-group {
  height: 50px;
  position: relative;
  width: 310px;
}

.dropdown-wrapper .dropdown-header {
  cursor: pointer;
  appearance: none;
  border: none;
  background: transparent;
  letter-spacing: 0.23px;
  line-height: 15px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
  pointer-events: all;
}

.dropdown-wrapper .animation {
  display: flex;
  height: 50px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 310px;
}

.dropdown-wrapper .animation:hover {
  opacity: 1;
}

.dropdown-wrapper .rectangle-452 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
  height: 310px;
  transform: rotate(-90deg);
  width: 3px;
  position: relative;
  top: -231%;
  left: calc(50% - 2px);
}

.dropdown-wrapper .dropdown {
  pointer-events: all;
  background: #323232;
  position: absolute;
  top: 70px;
  left: -250px;
  z-index: 6;
  width: 980px;
}

.dropdown-wrapper .dropdown-relative {
  position: relative;
  width: 100%;
  height: 465px;
  align-items: flex-start;
  display: flex;
  padding: 30.2px 18px;
}

.dropdown-wrapper .dropdown-link .animation {
  position: absolute;
  left: unset;
  right: 15px;
  height: 48px;
  width: 185px;
}

.dropdown-wrapper .dropdown-link .rectangle-452 {
  height: 195px;
  top: -68.6%;
}

.dropdown-wrapper .dropdown .d-flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  min-height: 374px;
  width: 455px;
}

.dropdown-wrapper .dropdown .d-mask-group-7 {
  height: 298px;
  object-fit: cover;
  width: 453px;
}

.dropdown-wrapper .dropdown .d-reliable-metal-am-pr {
  letter-spacing: 0.26px;
  line-height: 30px;
  margin-left: 0.5px;
  margin-top: 11px;
  min-height: 65px;
  width: 455px;
}

.dropdown-wrapper .dropdown .d-span0 {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: inherit;
  font-weight: 400;
}

.dropdown-wrapper .dropdown .d-overlap-group-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 0.26px;
  min-height: 388px;
  width: 452px;
  overflow-x: hidden;
}

.dropdown-wrapper .dropdown .d-overlap-group2 {
  height: 115px;
  position: relative;
  width: 452px;
  transition: all 0.2s ease;
}

.dropdown-wrapper .dropdown .d-overlap-group2:hover,
.dropdown-wrapper .dropdown .d-overlap-group:hover,
.dropdown-wrapper .dropdown .d-overlap-group1:hover {
  transform: translateX(13px);
}

.dropdown-wrapper .dropdown .d-color-block {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
  height: 100%;
  width: 3px;
  position: absolute;
  right: calc(100% + 10px);
}

.dropdown-wrapper .dropdown .d-title,
.dropdown-wrapper .dropdown .d-learn-the-technology,
.dropdown-wrapper .dropdown .d-see-it-in-action {
  left: 0;
  letter-spacing: 0.36px;
  line-height: 25px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.dropdown-wrapper .dropdown .d-learn-about-your-new {
  left: 0;
  letter-spacing: 0.2px;
  line-height: 25px;
  position: absolute;
  top: 38px;
  width: 452px;
}

.dropdown-wrapper .dropdown .d-overlap-group {
  height: 120px;
  margin-top: 29px;
  position: relative;
  width: 452px;
  transition: all 0.2s ease;
}

.dropdown-wrapper .dropdown .d-our-superpower-is-ne {
  color: #ffffff;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.2px;
  line-height: 25px;
  position: absolute;
  top: 43px;
  width: 452px;
}

.dropdown-wrapper .dropdown .d-overlap-group1 {
  height: 95px;
  margin-top: 29px;
  position: relative;
  width: 452px;
  transition: all 0.2s ease;
}

.dropdown-wrapper .dropdown .d-seeing-is-believing {
  left: 0;
  letter-spacing: 0.2px;
  line-height: 25px;
  position: absolute;
  top: 43px;
  width: 452px;
}
