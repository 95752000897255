.mltr-ns-1 {
  display: flex;
  height: 62px;
  margin-right: -38px;
  margin-top: 45px;
  width: 417px;
}

.mltr-twoit-adapts,
.mltr-threeit-finishes {
  height: 64px;
  letter-spacing: 0.35px;
  line-height: 35px;
  width: 417px;
}

.mltr-span0-1 {
  letter-spacing: 0.18px;
}
