.mhww-apply-now {
  align-items: flex-end;
  align-self: center;
  display: flex;
  height: 24px;
  margin-right: 28.35px;
  margin-top: 20px;
  min-width: 318px;
}

.mhww-overlap-group {
  height: 26px;
  margin-bottom: -4px;
  position: relative;
  width: 318px;
}

.mhww-speak-with-a-rivelin-expert {
  color: #309e56;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 19px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.mhww-right-angle-arrow {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  left: 0;
  min-width: 318px;
  padding: 1.8px 0px;
  position: absolute;
  top: 1px;
  transition: all 0.2s ease;
}

.mhww-right-angle-arrow:hover {
  transform: translate(5px, 0);
}

.mhww-angle-right {
  height: 16px;
  object-fit: cover;
  width: 9px;
}
