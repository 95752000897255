.small-top-link-wrapper {
  align-items: flex-end;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  height: 21px;
  margin-bottom: 0;
  min-width: 60px;
  transition: all 0.2s ease;
}

.small-top-link-wrapper:hover {
  color: #309e46;
}

.small-top-link {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 11px;
  margin-bottom: -7px;
  min-height: 23px;
  min-width: 60px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.small-top-link:hover {
  color: #309e56;
}

.small-top-link-wrapper.resources-link {
  align-self: unset;
  margin-bottom: unset;
  margin-left: 46px;
  min-width: 80px;
  transition: unset;
}

.small-top-link-wrapper.faq-link {
  align-self: unset;
  margin-bottom: unset;
  margin-left: 46px;
  min-width: 0;
  transition: unset;
}

.small-top-link-wrapper.resources-link .small-top-link {
  min-width: 80px;
  line-height: 20px;
  margin-bottom: -2.5px;
}

.small-top-link-wrapper.faq-link .small-top-link {
  min-width: 0;
  line-height: 20px;
  margin-bottom: -2.5px;
}

.small-top-link-wrapper.careers-link .small-top-link {
  line-height: 20px;
  margin-bottom: -2.5px;
}
