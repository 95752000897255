.footer-shrinker {
  background-color: #121213;
  display: flex;
  flex-direction: column;
  height: auto;
  //padding: 28.5px 81.5px;
  padding-bottom: 28.5px;
  z-index: 2;
  margin-top: 150px;
  width: 100%;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
    padding-bottom: 0px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
    padding-bottom: 0px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    height: auto;
    max-width: 991px;
    margin-top: 28px;
    padding-bottom: 0px;
  }
  @media (max-width: 799px) {
    max-width: 799px;
    margin-top: 28px;
    padding-bottom: 0px;
  }
}

.tagline-banner {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
  width: 100%;
  height: 35px;
  text-align: center;
  display: table;
  @media (min-width: 1279px) and (max-width: 1439px) {
    height: 32px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    height: 30px;
  }
  .span {
    align-content: center;
    justify-content: center;
    display: table-cell;
    vertical-align: middle;
    @media (min-width: 1279px) and (max-width: 1439px) {
      font-size: 18px;
    }
    @media (min-width: 799px) and (max-width: 1279px) {
      font-size: 16px;
    }
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
      height: 37px;
    }
  }
}

.footer {
  display: grid;
  place-content: center;
  flex: unset;
  padding-top: 40px;
  //padding-bottom: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 62px 210px 20px 62px;
  grid-template-areas: 'logo address contact-us nav' 'tortuga address contact-us nav' 'line line line line' 'policy policy socials socials';
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.85);
    padding-top: 0px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.76);
    padding-top: 0px;
    grid-template-rows: 27px 195px 71px 42px;
    height: 299px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    transform: scale(0.9);
    padding-top: 0px;
    padding-bottom: 0px;
    height: 430px;
    grid-template-columns: 249px auto 249px;
    grid-template-rows: 46px 136px 180px 20px 62px;
    grid-template-areas: 'logo tortuga address' 'nav tortuga address' 'nav tortuga contact-us' 'line line line' 'policy . socials';
  }
  @media (max-width: 799px) {
    transform: scale(0.9);
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 80px;
    height: 850px;
    grid-template-columns: auto auto;
    grid-template-rows: 90px 220px 190px 310px 13px 42px 36px;
    grid-template-areas: 'logo logo' 'address address' 'contact-us contact-us' 'nav tortuga' 'line line' 'policy policy' 'socials socials';
  }
}

.footer .rr_logo_lgt-1 {
  grid-area: logo;
  place-self: start center;
  object-fit: cover;
  height: 46px;
  width: 162px;
  @media (min-width: 799px) and (max-width: 1279px) {
    transform: translate(20px, 20px);
    place-self: center start;
  }
  @media (max-width: 799px) {
    width: 45%;
    height: auto;
    place-self: start start;
    transform: translateX(30px);
  }
}

.footer .rivelin-tortuga {
  grid-area: tortuga;
  place-self: end center;
  height: 195px;
  object-fit: cover;
  width: 116px;
  margin-right: 75px;
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: translateY(50px);
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    height: 300px;
    width: 160px;
    place-self: end start;
    transform: translate(-20px, 15px);
  }
  @media (max-width: 799px) {
    place-self: end end;
    transform: translateX(-80px);
  }
}

.footer .cyber-essentials-plus {
  grid-area: tortuga;
  place-self: end center;
  height: 143px;
  object-fit: cover;
  width: 120px;
  margin-left: 150px;
  margin-bottom: 30px;
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: translateY(50px);
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    place-self: end start;
    transform: translate(-20px, 15px);
  }
  @media (max-width: 799px) {
    place-self: end end;
    transform: translate(-140px, -160px);
  }
}

.footer .address {
  grid-area: address;
  place-self: stretch center;
  letter-spacing: 0;
  line-height: 35px;
  height: 173px;
  width: 208px;
  @media (min-width: 799px) and (max-width: 1279px) {
    place-self: stretch start;
    height: 180px;
  }
  @media (max-width: 799px) {
    place-self: start start;
    transform: translateX(30px);
  }
}

.footer .contact-us {
  grid-area: contact-us;
  place-self: stretch center;
  display: flex;
  flex-direction: column;
  height: 135px;
  width: 249px;
  @media (min-width: 799px) and (max-width: 1279px) {
    place-self: center start;
    transform: translateY(7px);
  }
  @media (max-width: 799px) {
    place-self: start start;
    transform: translateX(30px);
  }
}

.footer .contact-us-1 {
  cursor: pointer;
  height: 35px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
}

.footer .request-a-demo-13 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 35px;
  margin-top: 1px;
  white-space: nowrap;
}

.footer .link-1 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 35px;
  margin-top: 6px;
  white-space: nowrap;
}

.footer .nav-footer {
  grid-area: nav;
  place-self: stretch center;
  display: flex;
  flex-direction: column;
  height: 266px;
  width: 249px;
  @media (min-width: 799px) and (max-width: 1279px) {
    transform: translateX(20px);
    place-self: end center;
  }
  @media (max-width: 799px) {
    place-self: start start;
    transform: translateX(30px);
  }
}

.footer .rivelin-robotics {
  height: 35px;
  letter-spacing: 0;
  line-height: 47px;
  margin-top: -10.5px;
  white-space: nowrap;
}

.footer .place {
  letter-spacing: 0;
  line-height: 47px;
  margin-top: 14px;
  white-space: nowrap;
}

.footer .link-2 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 47px;
  white-space: nowrap;
}

.footer .line-1 {
  grid-area: line;
  place-self: end center;
  height: 1px;
  object-fit: cover;
  width: 1651px;
  @media (min-width: 799px) and (max-width: 1279px) {
    max-width: 776px;
  }
  @media (max-width: 799px) {
    max-width: 300px;
    margin-left: -140px;
  }
}

.footer .footer-small-print {
  grid-area: policy;
  place-self: end start;
  align-items: flex-start;
  display: flex;
  margin-left: 60px;
  min-width: 288px;
  position: relative;
  @media (min-width: 799px) and (max-width: 1279px) {
    margin-left: 20px;
    place-self: center start;
  }
  @media (max-width: 799px) {
    margin-left: -140px;
    place-self: center center;
  }
}

.footer .privacy-policy-link-container {
  height: 20px;
  margin-left: 7px;
  position: relative;
  width: 97px;
}

.footer .social-media-handles {
  grid-area: socials;
  place-self: end end;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 25px;
  margin-right: 60px;
  min-width: 199px;
  position: relative;
  @media (min-width: 799px) and (max-width: 1279px) {
    margin-right: 20px;
    place-self: center end;
  }
  @media (max-width: 799px) {
    margin-right: 0;
    place-self: center center;
    margin-left: -140px;
  }
}
