.instagram,
.instagram-1,
.instagram-2,
.instagram-3,
.instagram-4,
.instagram-5,
.instagram-6,
.instagram-7,
.instagram-8,
.instagram-9,
.instagram-10,
.instagram-11 {
  align-self: flex-end;
  background-image: url(../../static/img/instagram-1@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 23px;
  margin-bottom: 0.38px;
  margin-left: 18px;
  width: 23px;
}
