.lrg-careers {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1877px;
  .story-1 {
    > div {
      @media (max-width: 799px) {
        height: auto;
      }
      img.image {
        @media (max-width: 799px) {
          height: auto;
          position: relative;
          // top: unset;
          // left: unset;
        }
      }
    }
  }
}

.nav-bar-12 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 302px;
  z-index: 4;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-130px) translateY(-25px);
  }
}

.place-1 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.angles-right {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.who-we-are-link {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 4px;
  min-height: 29px;
  min-width: 120px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
}

.careers-placeholder {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 4px;
  min-height: 29px;
  min-width: 77px;
  white-space: nowrap;
}

.heading-1 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 40px;
  min-height: 214px;
  width: 80%;
  z-index: 3;
  gap: 25px;
  @media (max-width: 799px) {
    margin-top: 0;
  }
}

.our-people-is-what-m {
  color: #343638;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 30px;
  margin-top: -4px;
  @media (max-width: 1279px) {
    text-align: justify;
  }
}

.span0-1 {
  letter-spacing: 0.4px;
  line-height: 1em;
}

.span1-1 {
  font-weight: 700;
  letter-spacing: 0.4px;
}

.screen .span3 {
  text-decoration: underline;
  display: inline;
}

.screen .span3:hover {
  color: #309e56;
}

.overlap-group-container {
  align-items: flex-start;
  display: flex;
  margin-top: 1px;
  min-width: 366px;
  @media (max-width: 799px) {
    transform: translateX(-35px) scale(0.8);
  }
}

.button-container-1 {
  height: 51px;
  position: relative;
  width: 152px;
  left: 10px;
  top: 10px;
}

.primary-button-container-12 {
  height: 51px;
  margin-left: 28px;
  position: relative;
  width: 179px;
  top: 5px;
}

.open-positions {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  z-index: 5;
}

.split-banner {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  display: flex;
}

.join-our-teamsolve {
  letter-spacing: 0.22px;
  line-height: 48px;
  text-align: center;
  @media (max-width: 1279px) {
    line-height: 38px;
  }
}

.span1-2 {
  letter-spacing: 0.34px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    font-size: 32px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    font-size: 28px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    font-size: 30px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
  }
}

.flex-row-4 {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  margin-top: 26px;
  position: relative;
  @media (max-width: 1279px) {
    flex-direction: column;
  }
}

.autonomous-intellige {
  letter-spacing: 0.24px;
  line-height: 35px;
}

.span0-2 {
  letter-spacing: 0.22px;
}

.span1-3 {
  letter-spacing: 0.32px;
}

.line-2 {
  height: 2px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  margin-top: 30px;
  object-fit: cover;
}
