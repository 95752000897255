.apply-now {
  align-items: flex-end;
  display: flex;
  height: 35px;
  margin-bottom: 28.5px;
  margin-left: 98px;
  min-width: 194px;
  @media (max-width: 1279px) {
    transform: translateY(15px);
  }
}

.overlap-group1-1 {
  height: 37px;
  margin-bottom: -4.5px;
  position: relative;
  width: 194px;
}

.apply-now-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.right-angle-arrow {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: flex-end;
  left: 0;
  min-width: 194px;
  padding: 2.1px 0;
  position: absolute;
  top: 2px;
  transition: all 0.2s ease;
}

.right-angle-arrow:hover {
  transform: translate(5px, 0);
}

.angle-right {
  height: 23px;
  object-fit: cover;
  width: 13px;
}

.apply-now.apply-now-2 {
  cursor: pointer;
  left: 1288px;
  margin-bottom: unset;
  margin-left: unset;
  min-width: 206px;
  position: absolute;
  top: 657px;
}

.apply-now-2.mbl-learn-more {
  left: unset;
  position: unset;
  margin-right: -48px;
  .apply-now-1 {
    font-size: 18px;
    line-height: 1.5em;
  }
  .right-angle-arrow {
    transform: translate(-40%, -20%) scale(0.6) translate(40%, 20%);
    min-width: 395px;
  }
}

.apply-now.apply-now-2 .overlap-group1-1 {
  width: 206px;
}

.apply-now.apply-now-2 .right-angle-arrow {
  align-items: flex-end;
  min-width: 206px;
  padding: 2.6px 0;
}

.apply-now.apply-now-3 {
  cursor: pointer;
  left: 1169px;
  margin-bottom: unset;
  margin-left: unset;
  min-width: 325px;
  position: absolute;
  top: 657px;
}

.apply-now.apply-now-3 .overlap-group1-1 {
  width: 325px;
}

.apply-now.apply-now-3 .right-angle-arrow {
  align-items: flex-end;
  min-width: 325px;
  padding: 2.6px 0;
}

.apply-now.how-we-work-link {
  top: 403px;
  left: 1127px;
  position: absolute;
  height: 35px;
  min-width: 467px;
  display: flex;
  align-items: flex-end;
  margin-left: 0;
}

.apply-now.how-we-work-link .right-angle-arrow {
  align-items: flex-end;
  min-width: 467px;
  padding: 2.6px 0;
}

.apply-now.solutions-link {
  top: 370px;
  left: 0;
  position: absolute;
  height: 35px;
  min-width: 467px;
  display: flex;
  align-items: flex-end;
  margin-left: 0;
  .right-angle-arrow {
    align-items: flex-end;
    min-width: 370px;
    padding: 2.6px 0;
  }
}

.apply-now.solutions-link.mobile-version {
  top: 1675px;
  min-width: 354px;
  left: 34px;
  .apply-now-1 {
    font-size: 18px;
    line-height: 1.5em;
  }
  .right-angle-arrow {
    transform: translate(-50%, -20%) scale(0.6) translate(50%, 20%);
    min-width: 395px;
  }
}
