.problem {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 496px;
  @media (min-width: 799px) and (max-width: 1877px) {
    width: 400px;
  }
  @media (min-width: 1279px) and (max-width: 1279px) {
    width: 566px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    width: 730px;
  }
  @media (max-width: 799px) {
    width: 90vw;
  }
}

.problem-1 {
  letter-spacing: 0.24px;
  line-height: 55px;
  margin-bottom: -23px;
  margin-top: -12px;
  min-height: 32px;
  white-space: nowrap;
}

.additive-manufacturi {
  letter-spacing: 0.22px;
  line-height: 30px;
  margin-top: 26px;
  width: 100%;
}
