.google-pixel-6-pro {
  background-color: #000000;
  width: 100%;
  align-items: center;
  display: none !important;
  gap: 30px;
  // height: calc(80vh - 117px);
  @media (max-width: 799px) {
    display: flex !important;
  }
}

#mobile-video-wrapper {
  position: relative;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  svg {
    top: 10px;
    right: 10px;
    font-size: 48px;
    color: white;
    position: absolute;
    z-index: 4;
  }
}

.image-container {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow: hidden;
  display: flex;
  padding: 90px 0;
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
  .image-overlay {
    // top: 28vh;
    position: relative;
    z-index: 4;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: 'Atkinson Hyperlegible', Helvetica;
    margin-left: 40px;
    margin-right: 40px;
    align-self: center;
    @media (max-width: 400px) {
      margin-left: 25px;
      margin-right: 25px;
    }
    .overlay-big {
      font-size: 22px;
      margin-bottom: 40px;
    }
    .overlay-small {
      font-size: 16px;
      line-height: 1.5em;
      width: 55%;
      @media (max-width: 400px) {
        width: 70%;
      }
    }
    .lime-green {
      color: #96e9b0;
    }
    .video-player-button {
      position: absolute;
      right: 0;
      top: -50px;
    }
  }
}

.google-pixel-6-pro .scaler {
  height: 577px;
  position: relative;
  width: 412px;
  transform: translateY(-50%) scale(1.75) translateY(50%);
  @media (max-width: 670px) {
    transform: translateY(-50%) scale(1.53) translateY(50%);
  }
  @media (max-width: 585px) {
    transform: translateY(-50%) scale(1.3) translateY(50%);
  }
  @media (max-width: 500px) {
    transform: translateY(-50%) scale(1.2) translateY(50%);
  }
  @media (max-width: 462px) {
    transform: translateY(-50%) scale(1.15) translateY(50%);
  }
  @media (max-width: 440px) {
    transform: translateY(-50%) scale(1.05) translateY(50%);
  }
  @media (max-width: 400px) {
    transform: translateY(-50%) scale(1) translateY(50%);
  }
}

.google-pixel-6-pro .overlap-group5 {
  height: 470px;
  width: 412px;
}

.google-pixel-6-pro .home-hero-mobile {
  display: flex;
}

.google-pixel-6-pro .group-21 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 412px;
}

.google-pixel-6-pro .hero-container {
  height: 452px;
  position: relative;
  width: 412px;
}

.google-pixel-6-pro .hero-links {
  display: flex;
  height: 24px;
  justify-content: space-between;
  position: relative;
  width: 195px;
  margin-left: 50px;
  margin-top: 70px;
  @media (max-width: 400px) {
    margin-left: 25px;
    margin-top: 25px;
  }
}

.google-pixel-6-pro .the-robots {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin-bottom: 0.4px;
  margin-top: 0;
  min-width: 89.61px;
}

.google-pixel-6-pro .button-container {
  height: 24px;
  position: relative;
  width: 90px;
}

.google-pixel-6-pro .m-net-shape {
  align-items: flex-start;
  display: flex;
  margin-right: 0;
  min-width: 90px;
}

.google-pixel-6-pro .flex-row-home-mbl {
  justify-content: center;
  display: flex;
  margin-top: 5px;
  min-width: 338px;
  position: relative;
  @media (max-width: 440px) {
    margin-top: 16px;
  }
}

.google-pixel-6-pro .renishaw_white {
  height: 35px;
  margin-top: 2px;
  object-fit: cover;
  width: auto;
}

.google-pixel-6-pro .gkn_2 {
  height: 50px;
  margin-left: 31px;
  object-fit: cover;
  width: auto;
}

.google-pixel-6-pro ._white-container {
  justify-content: center;
  display: flex;
  margin-right: 2px;
  margin-top: 24px;
  min-width: 334px;
}

.google-pixel-6-pro .oerlikon {
  height: 28px;
  margin-top: 4px;
  object-fit: cover;
  width: auto;
}

.google-pixel-6-pro .ministry-of-defence_white {
  height: 35px;
  margin-left: 33px;
  object-fit: cover;
  width: auto;
}

.video-player-button::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.hide-button {
  display: none;
}
