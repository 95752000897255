.lrg-home-page {
  background-color: #000000;
}

.video-container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 160px);
  overflow: hidden;
  display: flex;
  @media (min-width: 1279px) and (max-width: 1439px) {
    min-height: calc(100vh - 192px);
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    min-height: calc(100vh - 110px);
  }
  @media (max-width: 799px) {
    display: none;
  }
  video {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
}
.video-overlay {
  position: relative;
  z-index: 4;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  margin-left: 20px;
  width: 100%;
  align-self: center;
  padding: 40px 0;
  .overlay-big {
    font-size: 36px;
    margin-bottom: 40px;
  }
  .overlay-small {
    font-size: 24px;
    line-height: 1.5em;
    width: 55%;
  }
  .lime-green {
    color: #96e9b0;
  }
  .hero-links {
    display: flex;
    position: relative;
    height: 40px;
    justify-content: space-between;
    width: 330px;
    margin-left: 50px;
    margin-top: 70px;
    @media (min-width: 1439px) and (max-width: 1877px) {
      transform: translate(-50%, -50%) scale(0.95) translate(50%, 50%);
    }
    @media (min-width: 1279px) and (max-width: 1439px) {
      transform: translate(-50%, -50%) scale(0.9) translate(50%, 50%);
    }
    @media (max-width: 1279px) {
      transform: translate(-50%, -50%) scale(0.9) translate(50%, 50%);
    }
    .link {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      min-width: 152px;
      .button-container {
        height: 40px;
        position: relative;
        width: 152px;
      }
    }
  }
}

.hero-banner {
  display: flex;
  flex: 1;
  margin-top: 0;
  z-index: 1;
  @media (min-width: 1279px) and (max-width: 1877px) {
    margin-top: 25px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    margin-top: 20px;
  }
}

.hero-banner .group-21 {
  overflow: hidden;
  align-items: flex-start;
  display: flex;
}

.hero-banner .hero-l-container {
  height: auto;
  position: relative;
  width: 100%;
}

.hero-banner .render-container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  video {
    width: 100%;
    height: auto;
    object-fit: cover;
    @media (max-width: 799px) {
      margin-left: 10px;
      width: calc(100% - 20px);
      object-fit: contain;
      // height: 450px;
    }
  }
  @media (max-width: 799px) {
    margin-bottom: 20px;
  }
}

.hero-banner .flex-row-home-desktop {
  justify-content: center;
  display: flex;
  min-width: 338px;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
  row-gap: 25px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: translateY(-50%) scale(0.9) translateY(50%);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: translateY(-50%) scale(0.7) translateY(50%);
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    transform: translateY(-50%) scale(0.6) translateY(50%);
  }
  @media (max-width: 799px) {
    flex-wrap: wrap;
    width: 799px;
    left: calc(calc(799px - 100vw) / -2);
  }
  @media (max-width: 670px) {
    transform: scale(0.88);
  }
  @media (max-width: 585px) {
    transform: scale(0.75);
  }
  @media (max-width: 500px) {
    transform: scale(0.64);
  }
  @media (max-width: 420px) {
    transform: scale(0.52);
  }
  @media (max-width: 345px) {
    transform: scale(0.48);
  }
}

.hero-banner .renishaw_white {
  height: auto;
  margin-top: 4px;
  object-fit: cover;
  width: 245px;
  margin-left: 60px;
  @media (max-width: 799px) {
    margin-left: 0;
    margin-right: 40px;
  }
}

.hero-banner .gkn_2 {
  height: auto;
  margin-left: 60px;
  object-fit: cover;
  width: 225px;
  @media (max-width: 799px) {
    margin-left: 40px;
  }
}

.hero-banner .oerlikon {
  height: auto;
  margin-left: 60px;
  object-fit: cover;
  width: 245px;
  @media (max-width: 799px) {
    margin-left: 0;
    margin-right: 40px;
  }
}

.hero-banner .ministry-of-defence_white {
  height: auto;
  margin-left: 60px;
  object-fit: cover;
  width: 235px;
  @media (max-width: 799px) {
    margin-left: 40px;
  }
}

.faq {
  flex: 1;
  letter-spacing: 1.5px;
  line-height: 33px;
  margin: 0 50px;
  z-index: 2;
  @media (max-width: 799px) {
    line-height: 1.9em;
    margin-left: 70px;
    margin-right: 70px;
  }
  @media (max-width: 670px) {
    margin-top: -10px;
  }
  @media (max-width: 585px) {
    margin-top: -10px;
  }
  @media (max-width: 500px) {
    margin-top: -20px;
    line-height: 1.5em;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (max-width: 420px) {
    margin-top: -40px;
  }
  @media (max-width: 345px) {
    margin-top: -40px;
  }
  .atkinsonhyperlegible-normal-white-17px {
    @media (max-width: 799px) {
      font-size: 15px;
    }
    @media (max-width: 500px) {
      font-size: 13px;
    }
  }
}

.faq ul {
  list-style: outside;
  margin-left: 25px;
}
