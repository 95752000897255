.primary-button {
  align-items: flex-end;
  background-color: #309e56;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  height: 54px;
  justify-content: flex-end;
  left: 0;
  min-width: 207px;
  padding: 5.9px 12.4px;
  position: absolute;
  top: 0;
}

.request-a-demo-12 {
  letter-spacing: 0.4px;
  line-height: 12px;
  min-height: 27px;
  min-width: 180px;
  text-align: center;
  white-space: nowrap;
}

.primary-button.primary-button-1,
.primary-button.primary-button-3,
.primary-button.primary-button-5,
.primary-button.primary-button-7,
.primary-button.primary-button-9,
.primary-button.primary-button-11,
.primary-button.primary-button-13,
.primary-button.primary-button-15,
.primary-button.primary-button-17,
.primary-button.primary-button-19,
.primary-button.primary-button-21,
.primary-button.primary-button-23 {
  box-shadow: inset 5px 7px 6px #00000029;
  opacity: 0;
  padding: 6.4px 12.4px;
  transition: all 0.2s ease;
}

.primary-button.primary-button-1:hover,
.primary-button.primary-button-3:hover,
.primary-button.primary-button-5:hover,
.primary-button.primary-button-7:hover,
.primary-button.primary-button-9:hover,
.primary-button.primary-button-11:hover,
.primary-button.primary-button-13:hover,
.primary-button.primary-button-15:hover,
.primary-button.primary-button-17:hover,
.primary-button.primary-button-19:hover,
.primary-button.primary-button-21:hover,
.primary-button.primary-button-23:hover {
  opacity: 1;
}

.primary-button.primary-button-1 .request-a-demo-12,
.primary-button.primary-button-3 .request-a-demo-12,
.primary-button.primary-button-5 .request-a-demo-12,
.primary-button.primary-button-7 .request-a-demo-12,
.primary-button.primary-button-9 .request-a-demo-12,
.primary-button.primary-button-11 .request-a-demo-12,
.primary-button.primary-button-13 .request-a-demo-12,
.primary-button.primary-button-15 .request-a-demo-12,
.primary-button.primary-button-17 .request-a-demo-12,
.primary-button.primary-button-19 .request-a-demo-12,
.primary-button.primary-button-21 .request-a-demo-12,
.primary-button.primary-button-23 .request-a-demo-12 {
  line-height: 13px;
}

.primary-button.primary-button-6 {
  cursor: unset;
}
