.button-0 {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 0;
  min-width: 152px;
  position: absolute;
  top: 0;
}

.overlap-group-12 {
  height: 40px;
  position: relative;
  width: 152px;
}

.rectangle-450 {
  border-radius: 60px;
  height: 152px;
  left: 56px;
  position: absolute;
  top: -56px;
  transform: rotate(-90deg);
  width: 40px;
}

.net-shape {
  left: 26px;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.button-0.button-0-1 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.button-0.button-0-1:hover {
  opacity: 1;
}
.button-0.button-0-1 .rectangle-450 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
}

