.lrg-why-we-exist {
  align-items: flex-start;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1877px;
}

.nav-bar-14 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 213px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-130px) translateY(-25px);
  }
}

.place-3 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.angles-right-2 {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.who-we-are-placeholder-1 {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 5px;
  min-height: 29px;
  min-width: 130px;
  text-align: center;
  white-space: nowrap;
}

.span0-7 {
  text-decoration: underline;
}

.top-copy-1 {
  align-self: center;
  display: flex;
  margin-left: 1px;
  margin-top: 98px;
  width: 73.7%;
  z-index: 4;
  @media (max-width: 799px) {
    width: 85%;
    margin-top: 0px;
  }
}

.heading-1-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.we-exist-to-drive-th {
  letter-spacing: 0.4px;
  line-height: 55px;
  margin-bottom: -3px;
  margin-top: -8px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    font-size: 34px;
    margin-top: -26px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    font-size: 30px;
    margin-top: -52px;
  }
  @media (max-width: 1279px) {
    font-size: 18px;
    margin-top: -90px;
    line-height: 24px;
  }
  @media (max-width: 799px) {
    font-size: 18px;
    margin-top: 0px;
    line-height: 26px;
  }
}

.as-additive-manufact {
  letter-spacing: 0.48px;
  line-height: 35px;
  margin-top: 31px;
  text-align: justify;
  @media (min-width: 1439px) and (max-width: 1877px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (max-width: 1279px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.solutions {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
  z-index: 5;
  gap: 56px;
  @media (min-width: 799px) and (max-width: 1279px) {
    gap: 35px;
  }
}

.split-banner-1 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  width: 100%;
}

.overlap-group-28 {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 222px;
  justify-content: center;
  width: 100%;
  padding: 47px 117px;
  @media (min-width: 710px) and (max-width: 1279px) {
    padding: 20px 40px;
  }
  @media (max-width: 710px) {
    padding: 5px 10px;
    .span1-4 {
      font-size: 20px;
      line-height: 1em;
    }
  }
}

.solutionsreliable-m {
  letter-spacing: 0.22px;
  line-height: 48px;
  text-align: center;
  @media (min-width: 799px) and (max-width: 1279px) {
    line-height: 40px;
  }
  @media (max-width: 799px) {
    line-height: 36px;
  }
  @media (max-width: 710px) {
    line-height: 32px;
  }
}

.span1-4 {
  letter-spacing: 0.34px;
  @media (min-width: 799px) and (max-width: 1279px) {
    font-size: 28px;
  }
  @media (max-width: 799px) {
    font-size: 24px;
  }
}

.support-removal,
.finishing-polishing,
.platform-removal,
.depowdering {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 38px;
  min-height: 482px;
  gap: 30px;
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin-left: 38px;
    .atkinsonhyperlegible-bold-onyx-24px {
      font-size: 23px;
      line-height: 30px;
    }
    .atkinsonhyperlegible-normal-onyx-22px {
      font-size: 20px;
      line-height: 26px;
    }
    .atkinsonhyperlegible-bold-onyx-32px {
      font-size: 30px;
    }
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    margin-left: 38px;
    .atkinsonhyperlegible-bold-onyx-24px {
      font-size: 20px;
      line-height: 15px;
    }
    .atkinsonhyperlegible-normal-onyx-22px {
      font-size: 15px;
      line-height: 20px;
    }
    .atkinsonhyperlegible-bold-onyx-32px {
      font-size: 24px;
    }
  }
  @media (max-width: 799px) {
    margin-left: 5vw;
    margin-right: 5vw;
    .atkinsonhyperlegible-bold-onyx-24px {
      font-size: 20px;
      line-height: 15px;
    }
    .atkinsonhyperlegible-normal-onyx-22px {
      font-size: 15px;
      line-height: 20px;
    }
    .atkinsonhyperlegible-bold-onyx-32px {
      font-size: 24px;
    }
  }
  > .atkinsonhyperlegible-bold-onyx-22px {
    width: unset;
  }
}

.title-5 {
  letter-spacing: 0.32px;
  line-height: 35px;
  margin-top: -2px;
  min-height: 67px;
  width: 1102px;
  @media (max-width: 799px) {
    margin-top: -30px;
    line-height: 25px;
  }
}

.span0-6 {
  letter-spacing: 0.22px;
}

.flex-row-10 {
  align-items: flex-start;
  display: flex;
  position: relative;
  gap: 71px;
  @media (min-width: 1279px) and (max-width: 1439px) {
    gap: 40px;
    flex-wrap: wrap;
    .at-a-glance {
      order: -1;
    }
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    gap: 25px;
    align-items: flex-end;
    flex-wrap: wrap;
    .at-a-glance {
      order: -1;
    }
  }
  @media (max-width: 799px) {
    gap: 25px;
    align-items: flex-end;
    flex-wrap: wrap;
    .at-a-glance {
      order: -1;
    }
  }
}

.line-2-1 {
  height: 2px;
  object-fit: cover;
  width: 80%;
  @media (min-width: 1439px) and (max-width: 1877px) {
    width: 85%;
  }
  @media (max-width: 1439px) {
    width: 95%;
  }
}

.title-4 {
  letter-spacing: 0.32px;
  line-height: 35px;
  min-height: 67px;
  width: 1102px;
  @media (max-width: 799px) {
    margin-top: -30px;
    line-height: 25px;
  }
}

.flex-row-11 {
  align-items: flex-start;
  display: flex;
  height: 347px;
  margin-top: 10px;
  min-width: 1346px;
  position: relative;
}

.layer-1 {
  display: flex;
  flex: 1;
  margin: 10.7px 5px 9.7px 4.3px;
  width: 68.74px;
}

.no-code-programming-1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 68.74px;
  padding: 17.1px 6px;
}

.path-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.26px;
  min-height: 5px;
  width: 21px;
}

.path-183 {
  height: 1px;
  margin-left: 0.03px;
  object-fit: cover;
  width: 21px;
}

.path-184 {
  height: 1px;
  margin-top: 2px;
  object-fit: cover;
  width: 21px;
}

.flex-col-26 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  min-height: 17px;
  width: 26px;
}

.path-container-1 {
  align-items: flex-start;
  display: flex;
  margin-left: 0.08px;
  min-width: 26px;
}

.path-179 {
  height: 6px;
  object-fit: cover;
  width: 6px;
}

.path-18 {
  height: 6px;
  margin-left: 3px;
  object-fit: cover;
  width: 6px;
}

.path-182 {
  height: 1px;
  margin-left: 0;
  margin-top: 4px;
  object-fit: cover;
  width: 26px;
}

.path-185 {
  height: 1px;
  margin-top: 4px;
  object-fit: cover;
  width: 26px;
}
