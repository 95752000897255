.hero-large-desktop-1 {
  flex: 1;
  z-index: 2;
  margin: 40px 5px 0 226px;
  @media (min-width: 1279px) and (max-width: 1877px) {
    margin: 20px 5px 0 100px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    margin: 0 5px 0 80px;
  }
  @media (max-width: 799px) {
    margin: 0 5px 0 15px;
  }
}

.title-3 {
  display: flex;
}

.careers-1 {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 65px;
  margin-top: 14px;
  text-align: left;
  @media (max-width: 799px) {
    font-size: 45px;
  }
}
