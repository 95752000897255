.lrg-who-we-are {
  align-items: flex-start;
  background-color: #f6f8f9;
}
.lrg-who-we-are .primary-button-container-13 {
  height: 51px;
  margin-left: 35px;
  position: relative;
  width: 179px;
  top: -3px;
}

.lrg-who-we-are .nav-bar-13 {
  align-items: flex-end;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 202px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-125px) translateY(-25px);
  }
}

.lrg-who-we-are .place-2 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.lrg-who-we-are .angles-right-1 {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.lrg-who-we-are .who-we-are-placeholder {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 4px;
  min-height: 29px;
  min-width: 120px;
  text-align: center;
  white-space: nowrap;
}

.lrg-who-we-are .span0-5 {
  text-decoration: underline;
}

.lrg-who-we-are .top-copy {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  height: 809px;
  justify-content: space-between;
  margin-right: 57px;
  margin-top: 98px;
  width: 1574px;
  z-index: 5;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.9) translateX(135px) translateY(20px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.85) translateX(245px) translateY(-20px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.55) translateX(791px) translateY(-300px);
  }
}

.lrg-who-we-are .wwa-shrinker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 1878px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    min-height: 3100px;
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    min-height: 2900px;
    max-width: 1279px;
  }
  @media (max-width: 1279px) {
    max-height: 2200px;
    max-width: 799px;
  }
  @media (max-width: 799px) {
    display: none;
  }
}

.lrg-who-we-are .heading-1-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 304px;
  min-height: 304px;
}

.lrg-who-we-are .factories-of-the-future-today {
  letter-spacing: 0.4px;
  line-height: 55px;
  margin-bottom: -3px;
  margin-top: -8px;
  min-height: 52px;
  white-space: nowrap;
}

.lrg-who-we-are .we-are-rivelinwe-a {
  letter-spacing: 0.48px;
  line-height: 35px;
  margin-top: 31px;
  min-height: 137px;
  width: 1576px;
}

.lrg-who-we-are .overlap-group-container-2 {
  align-items: flex-start;
  display: flex;
  margin-top: 40px;
  min-width: 366px;
}

.lrg-who-we-are .button-container-4 {
  height: 51px;
  position: relative;
  width: 152px;
  left: 11px;
  top: 2.3px;
}

.lrg-who-we-are .past-present-future {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 173px;
  max-height: 466px;
  min-height: 466px;
  padding: 0.5px 0;
  @media (max-width: 1279px) {
    padding-top: 30px;
  }
}

.lrg-who-we-are .past {
  letter-spacing: 0;
  line-height: 38px;
  min-height: 41px;
  white-space: nowrap;
}

.lrg-who-we-are .past-present-future-item {
  letter-spacing: 0;
  line-height: 38px;
  margin-top: 20px;
  min-height: 70px;
  width: 1403px;
}

.lrg-who-we-are .past-present-future-item-1 {
  letter-spacing: 0;
  line-height: 38px;
  margin-top: 34px;
  min-height: 41px;
  white-space: nowrap;
}

.lrg-who-we-are .overlap-group6 {
  align-self: center;
  height: 1753px;
  margin-left: 9.05px;
  margin-top: 35px;
  position: relative;
  width: 1833px;
  z-index: 4;
}

.lrg-who-we-are .why-we-exist-copy {
  height: 1260px;
  left: 115px;
  position: absolute;
  top: 0;
  width: 1508px;
  pointer-events: none;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.9) translateX(10px) translateY(-40px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.8) translateX(10px) translateY(-250px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.53) translateX(40px) translateY(-1210px);
  }
}

.lrg-who-we-are .team-rb {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 634px;
  position: absolute;
  top: 1120px;
  width: 424px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(1) translateX(450px) translateY(-80px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(1) translateX(450px) translateY(-280px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.85) translateX(600px) translateY(-1100px);
  }
}

.lrg-who-we-are .team-da {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 470px;
  min-height: 634px;
  position: absolute;
  top: 1120px;
  width: 424px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(1) translateX(475px) translateY(-80px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(1) translateX(475px) translateY(-280px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.85) translateX(495px) translateY(-1100px);
  }
}

.lrg-who-we-are .team-dm {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 925px;
  min-height: 644px;
  position: absolute;
  top: 1108px;
  width: 453px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(1) translateX(-490px) translateY(590px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(1) translateX(-490px) translateY(390px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.85) translateX(-507px) translateY(-430px);
  }
}

.lrg-who-we-are .team-ih {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 1409px;
  min-height: 634px;
  position: absolute;
  top: 1120px;
  width: 424px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(1) translateX(-465px) translateY(588px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(1) translateX(-465px) translateY(388px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.85) translateX(-609px) translateY(-433px);
  }
}

.lrg-who-we-are .overlap-group-27 {
  background-position: 50% 50%;
  background-size: cover;
  height: 535px;
  margin-left: 0;
  position: relative;
  width: 424px;
}

.lrg-who-we-are ._nobkg {
  height: 491px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  width: 408px;
}

.lrg-who-we-are .icon-linkedin {
  cursor: pointer;
  height: 43px;
  left: 361px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 43px;
}

.lrg-who-we-are .name-1 {
  letter-spacing: 0;
  line-height: 38px;
  margin-top: 21px;
  min-height: 82px;
  white-space: nowrap;
}

.lrg-who-we-are .overlap-group3-2 {
  height: 545px;
  margin-left: 0;
  position: relative;
  width: 453px;
}

.lrg-who-we-are .tim-hufner-3-wcnx-ptrr50-unsplash-3 {
  height: 535px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 424px;
}

.lrg-who-we-are .david-mason_nobkg1 {
  height: 545px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 453px;
}

.lrg-who-we-are .icon-linkedin-1 {
  cursor: pointer;
  height: 43px;
  left: 376px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 43px;
}

.lrg-who-we-are .name-2 {
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 14.55px;
  margin-top: 21px;
  min-height: 82px;
  white-space: nowrap;
}

.lrg-who-we-are .why-we-exist-copy .apply-now {
  pointer-events: all;
}

.lrg-who-we-are .overlap-group5 {
  height: 1260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1508px;
}

.lrg-who-we-are .rivelin-tortuga-5 {
  height: 1260px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 749px;
}

.lrg-who-we-are .reimagine-a-world-wi {
  left: 740px;
  letter-spacing: 0;
  line-height: 52px;
  position: absolute;
  text-align: right;
  top: 176px;
  width: 768px;
}

.lrg-who-we-are .in-a-system-where-co {
  left: 794px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  text-align: right;
  top: 371px;
  width: 702px;
}
