.large-header-link-wrapper {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0;
  margin-left: 30px;
  min-width: 310px;
}

.overlap-group {
  height: 50px;
  position: relative;
  width: 310px;
}

.large-header-link {
  left: 7px;
  letter-spacing: 0.23px;
  line-height: 15px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.animation {
  cursor: pointer;
  display: flex;
  height: 50px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  width: 310px;
}

.animation:hover {
  opacity: 1;
}

.rectangle-452 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
  height: 310px;
  transform: rotate(-90deg);
  width: 3px;
  position: relative;
  top: -214%;
  left: calc(50% - 2px);
}

.large-header-link-wrapper.how-we-work {
  align-items: flex-end;
  min-width: 167px;
}

.large-header-link-wrapper.how-we-work .overlap-group,
.large-header-link-wrapper.how-we-work .animation {
  height: 48px;
  width: 167px;
}

.large-header-link-wrapper.how-we-work .large-header-link {
  left: 6px;
  text-align: center;
  top: 6px;
}

.large-header-link-wrapper.how-we-work .rectangle-452 {
  height: 166.57px;
  top: -77%;
}

.large-header-link-wrapper.who-we-are {
  align-items: flex-end;
  min-width: 155px;
}

.large-header-link-wrapper.who-we-are .overlap-group,
.large-header-link-wrapper.who-we-are .animation {
  height: 49px;
  width: 155px;
}

.large-header-link-wrapper.who-we-are .large-header-link {
  text-align: center;
  top: 7px;
}

.large-header-link-wrapper.who-we-are .rectangle-452 {
  height: 154.92px;
  top: -61.43%;
}

.large-header-link-wrapper.why-we-exist {
  align-items: flex-end;
  cursor: pointer;
  margin-bottom: unset;
  min-width: 162px;
}

.large-header-link-wrapper.why-we-exist .overlap-group,
.large-header-link-wrapper.why-we-exist .animation {
  height: 49px;
  width: 162px;
}

.large-header-link-wrapper.why-we-exist .large-header-link {
  left: 2px;
  text-align: center;
  top: 7px;
}

.large-header-link-wrapper.why-we-exist .rectangle-452 {
  height: 161.92px;
  top: -68.6%;
}

.large-header-link-wrapper.careers-link,
.large-header-link-wrapper.resources-link,
.large-header-link-wrapper.faq-link,
.large-header-link-wrapper.contact-us-link,
.large-header-link-wrapper.request-a-demo-link {
  align-items: flex-end;
  cursor: pointer;
  margin-bottom: unset;
  margin-left: 19px;
  min-width: 162px;
}

.large-header-link-wrapper.careers-link .overlap-group,
.large-header-link-wrapper.resources-link .overlap-group,
.large-header-link-wrapper.faq-link .overlap-group,
.large-header-link-wrapper.contact-us-link .overlap-group,
.large-header-link-wrapper.request-a-demo-link .overlap-group {
  height: 49px;
  width: 162px;
}

.large-header-link-wrapper.careers-link .large-header-link,
.large-header-link-wrapper.resources-link .large-header-link,
.large-header-link-wrapper.faq-link .large-header-link,
.large-header-link-wrapper.contact-us-link .large-header-link,
.large-header-link-wrapper.request-a-demo-link .large-header-link {
  left: 2px;
  text-align: center;
  top: 7px;
}
