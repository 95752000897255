.linkedin {
  align-self: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 23px;
  margin-left: 18px;
  margin-top: 0;
  width: 23px;
}
