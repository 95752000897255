.resource-entry-card {
  display: flex;
  margin: 10px;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: calc(100% - 20px);
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
   .card-container {
    padding: 10px;
  }
  .preview-image {
    width: 100%;
  }
}

.small {
  color: #121213;
  font-family: 'Atkinson Hyperlegible', Helvetica;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
}
