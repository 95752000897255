.at-a-glance {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateY(-50px);
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: unset;
    margin-top: 10px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    transform: unset;
  }
}

.at-a-glance-title {
  letter-spacing: 0.24px;
  line-height: 55px;
  margin-bottom: -23px;
  margin-left: 0px;
  margin-top: -12px;
  min-height: 32px;
  white-space: nowrap;
}

.at-a-glance-icons {
  align-items: flex-start;
  display: flex;
  margin-top: 30px;
  gap: 30px;
  flex-direction: column;
  @media (min-width: 1279px) and (max-width: 1439px) {
    flex-direction: row;
    gap: 80px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.no-code-programming,
.scan-plan,
.flexible-fixturing,
.tool-wear-monitoring,
.automatic-system-calibration {
  align-items: center;
  display: flex;
  margin-left: 0;
  gap: 14px;
  @media (min-width: 799px) and (max-width: 1279px) {
    flex-direction: column;
  }
}

.icon-autonomous-programming {
  object-fit: cover;
  width: 78px;
}

.autonomous-programming {
  height: 35px;
  letter-spacing: 0.14px;
  line-height: 16px;
  margin-bottom: -16.1px;
  margin-right: -2px;
  white-space: nowrap;
  width: 92px;
}

.icon-scan-plan {
  object-fit: cover;
  width: 78px;
}

.scan-plan-name {
  height: 19px;
  letter-spacing: 0.14px;
  line-height: 16px;
  margin-bottom: -8.6px;
  margin-right: -2px;
  white-space: nowrap;
  width: 83px;
}

.icon-flexible-fixturing {
  object-fit: cover;
  width: 78px;
}

.flexible-fixturing-name {
  height: 19px;
  letter-spacing: 0.14px;
  line-height: 16px;
  margin-bottom: -9.3px;
  margin-right: -2px;
  white-space: nowrap;
  width: 118px;
}

.icon-tool-wear {
  object-fit: cover;
  width: 78px;
}

.tool-wear-monitoring-name {
  height: 19px;
  letter-spacing: 0.14px;
  line-height: 16px;
  margin-right: -2px;
  margin-top: 5px;
  white-space: nowrap;
  width: 145px;
}

.icon-automatic-calibration {
  background-color: #309e56;
  border-radius: 6px;
  display: flex;
  width: 78px;
}

.automatic-system-calibration-name {
  height: 33px;
  margin-bottom: -16.5px;
  object-fit: cover;
  width: 127px;
}
