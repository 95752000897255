.button-hover {
  align-items: center;
  background-color: #309e56;
  border-radius: 8px;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  left: 0;
  min-width: 171px;
  padding: 0 46px;
  position: absolute;
  top: 0;
}

.submit-1 {
  letter-spacing: 0;
  line-height: 30px;
  min-height: 29px;
  min-width: 76px;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 799px) {
    font-size: 30px;
  }
}

.button-hover.button,
.button-hover.button-2 {
  background-color: #343638;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-hover.button:hover,
.button-hover.button-2:hover {
  opacity: 0;
}
