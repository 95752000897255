html {
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 12px;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #c2c6d4;
}

body {
  overflow-x: hidden;
  background: black;
  display: flex;
  flex-flow: column;
  align-items: center;
}

#app {
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    max-width: 991px;
  }
}

#app .screen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100vw;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    max-width: 991px;
  }
}

.overlap-group-21,
.overlap-group-22 {
  align-items: flex-start;
  background-color: #343638;
  display: flex;
  height: 837px;
  justify-content: flex-end;
  margin-top: 180px;
  min-width: 1428px;
  padding: 21px 79.5px;
}

.scroll-group-2-1::-webkit-scrollbar,
.scroll-group-2-2::-webkit-scrollbar {
  display: none;
  width: 0;
}

.scroll-group-2-1,
.scroll-group-2-2 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  height: 747px;
  min-width: 1114px;
  overflow-y: scroll;
}

.my-engineering-journ-1 {
  height: 1678px;
  letter-spacing: 0.64px;
  line-height: 38px;
  margin-bottom: -977px;
  text-align: center;
  width: 1114px;
}

.icon-close-1,
.icon-close-2 {
  height: 31px;
  margin-left: 47px;
  margin-top: 32.51px;
  object-fit: cover;
  width: 31px;
}

.i-am-sofia-alatorre {
  height: 1917px;
  letter-spacing: 0.64px;
  line-height: 38px;
  margin-bottom: -1216px;
  text-align: center;
  width: 1114px;
}

#overlay-da-story,
#overlay-sa-story {
  background-color: #343638e0;
}

.aa-Autocomplete,
.aa-DetachedOverlay {
  font-family: Atkinson Hyperlegible, Helvetica !important;
}

.aa-Panel {
  position: fixed;
  font-family: Atkinson Hyperlegible, Helvetica !important;
}
.aa-Panel .aa-SourceFooter {
  display: none;
}
.aa-Panel .aa-SourceFooter .aa-Item .aa-ItemIcon {
  display: none;
}

.aa-Panel .aa-SourceFooter .aa-Item .aa-ItemIcon.aa-ItemDescription {
  color: #979797;
}
.aa-ItemDescription {
  font-family: inherit !important;
  color: #727272;
}

