/* @import './node_modules/include-media/dist/_include-media.scss' */

.search-bar {
  align-items: flex-start;
  display: flex;
  height: 18px;
  margin-left: 71px;
  margin-top: -13px;
  min-width: 158px;
  input {
    font-weight: 500;
  }
}

.search-bar#mobile-search {
  margin-top: -15px;
  padding-bottom: 50px;
  input {
    font-weight: 500;
  }
  @media (max-width: 1279px) {
    margin-top: 0;
  }
  @media (max-width: 680px) {
    margin-top: -10px;
    padding-bottom: 45px;
  }
}
.search-bar#search .aa-Autocomplete {
  --color-background: #ffffff !important;
  width: 100%;
}
.search-bar#mobile-search .aa-Autocomplete {
  --color-background: #ffffff !important;
  --color-text: #000000;
  width: 100%;
}
.aa-Autocomplete, .aa-Panel, .aa-DetachedContainer {
  --color-input-icon: #309e56 !important;
}
.aa-ClearButton {
  & * {
    //color: #d30d0d;
  }
}
.aa-Item {
  .aa-ItemDescription {
    color: #727272 !important;
  }
}
.search-bar#search .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input,
.aa-DetachedFormContainer .aa-Form .aa-InputWrapper .aa-Input {
  color: #000000 !important;
}
//.search-bar#mobile-search .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input,
.aa-DetachedFormContainer .aa-Form .aa-InputWrapper .aa-Input {
  color: #ffffff !important;
}
.aa-DetachedOverlay .aa-Input {
  font-weight: 500 !important;
}
.search {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: -3.5px;
  min-height: 20px;
  min-width: 50px;
  white-space: nowrap;
  color: #ffffff;
}
.icon-search {
  height: 17px;
  margin-left: 91px;
  margin-top: 0;
  object-fit: cover;
  width: 17px;
}
.search-bar.search-bar-1 .search,
.search-bar.search-bar-2 .search,
.search-bar.search-bar-3 .search,
.search-bar.search-bar-4 .search,
.search-bar.search-bar-5 .search,
.search-bar.search-bar-6 .search,
.search-bar.search-bar-7 .search,
.search-bar.search-bar-8 .search,
.search-bar.search-bar-9 .search,
.search-bar.search-bar-10 .search,
.search-bar.search-bar-11 .search {
  align-self: unset;
  line-height: 20px;
  margin-bottom: unset;
  margin-top: -1px;
}
