.header-wrapper {
  position: unset;
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  z-index: 6;
  width: 100vw;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (max-width: 1279px) {
    max-width: 991px;
  }
}

.announcement-banner {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
  width: 100%;
  height: 35px;
  text-align: center;
  display: table;
  @media (min-width: 1279px) and (max-width: 1439px) {
    height: 32px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    height: 30px;
  }
  .span {
    align-content: center;
    justify-content: center;
    display: table-cell;
    vertical-align: middle;
    @media (min-width: 1279px) and (max-width: 1439px) {
      font-size: 18px;
    }
    @media (min-width: 799px) and (max-width: 1279px) {
      font-size: 16px;
    }
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14px;
      height: 37px;
      padding: 0 10px;
    }
  }
}

.header-small {
  align-items: flex-start;
  background-color: #121213;
  height: 80px;
  width: 100%;
  max-width: 991px;
  display: none;
  @media (max-width: 1279px) {
    display: block;
  }
  @media (max-width: 799px) {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    height: auto;
  }
}

.header-small .rr_logo_lgt {
  cursor: pointer;
  width: 160px;
  height: auto;
  object-fit: cover;
  position: absolute;
  right: 20px;
  top: 18px;
  @media (max-width: 799px) {
    position: relative;
    top: unset;
    right: unset;
    width: 120px;
  }
}

.header-small .expand-icon {
  cursor: pointer;
  color: white;
  height: 100%;
  width: 70px;
  font-size: 48px;
  display: flex;
  place-items: center;
  padding-left: 20px;
  @media (max-width: 799px) {
    padding-left: 0;
    width: auto;
    height: auto;
  }
  svg {
    @media (max-width: 799px) {
      width: 25px;
      height: auto;
    }
  }
}

.header-small .dropdown {
  pointer-events: all;
  background: #121213;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 6;
  max-width: 380px;
  width: 100%;
  > * {
    margin: 20px;
    border-bottom: white 1px solid;
    height: 30px;
  }
  .link-1 {
    transform: translateY(-6px);
  }
  .robotic-finishing-systems-wrapper {
    min-height: 30px;
    height: unset;
    .robotic-finishing-div {
      font-size: 24px;
      transform: translateY(-6px);
      > svg {
        position: absolute;
        right: 22px;
        top: 0;
      }
    }
    .robotic-finishing-dropdown .shd-text {
      padding-left: 30px;
      display: flex;
      align-items: center;
      margin: 20px 0;
    }
  }
}

.header-small .large-header-link-wrapper {
  .animation {
    display: none;
  }
  .large-header-link {
    padding-top: 12px;
    left: 6px;
  }
}

.header-large-desktop {
  align-items: flex-start;
  background-color: #121213;
  display: flex;
  flex-direction: column;
  height: 160px;
  padding: 18.1px 62.3px;
  @media (min-width: 1279px) and (max-width: 1877px) {
    padding: 0;
  }
  @media (max-width: 1279px) {
    display: none;
  }
}

.header-large-desktop .nav-bar {
  align-items: center;
  display: flex;
  height: 21px;
  margin-left: 102.37px;
  min-width: 415px;
  position: relative;
  @media (min-width: 1279px) and (max-width: 1877px) {
    margin-top: 18.1px;
    margin-left: 34px;
  }
}

.header-large-desktop .flex-row {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-top: 32px;
  min-width: 1651px;
  position: relative;
  top: 100px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    min-width: 1439px;
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    min-width: 1279px;
    max-width: 1279px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    min-width: 799px;
    max-width: 799px;
  }
}

.header-large-desktop .group-20 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  height: 60px;
  position: absolute;
  bottom: 46px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.97) translateX(-154px);
    left: 165px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-230px);
    left: 165px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    max-width: 799px;
  }
}

.header-large-desktop .request-a-demo {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  min-width: 207px;
}

.header-large-desktop .primary-button-container {
  border-radius: 60px;
  height: 54px;
  position: relative;
  width: 207px;
}

.header-large-desktop .rr_logo_lgt {
  cursor: pointer;
  height: 77px;
  object-fit: cover;
  width: 273px;
  position: absolute;
  right: 62px;
  bottom: 46px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    height: unset;
    width: 240px;
    right: 45px;
    bottom: 50px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    height: unset;
    width: 220px;
    right: 15px;
    bottom: 50px;
  }
}
