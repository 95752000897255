.google-pixel-6-pro .m-button-0 {
  align-items: flex-start;
  display: flex;
  height: 24px;
  left: 0;
  min-width: 90px;
  position: absolute;
  top: 0;
}

.m-button-0 .overlap-group {
  height: 24px;
  position: relative;
  width: 90px;
}

.m-button-0 .rectangle-450 {
  border: 2px solid #ffffff;
  border-radius: 60px;
  height: 90px;
  left: 33px;
  position: absolute;
  top: -33px;
  transform: rotate(-90deg);
  width: 24px;
}

.m-button-0 .net-shape-1 {
  left: 19px;
  letter-spacing: 0.2px;
  line-height: 11px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  cursor: pointer;
}

.m-button-0 .net-shape-1 svg {
  font-weight: normal;
}

.m-button-0 .m-button-0.button-0-1 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.m-button-0 .m-button-0.button-0-1:hover {
  opacity: 1;
}

.m-button-0 .m-button-0.button-0-1 .rectangle-450 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
}
