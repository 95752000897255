.l-how-we-work {
  align-items: center;
  background-color: #f6f8f9;
}

.l-how-we-work .nav-bar-13 {
  align-items: flex-end;
  align-self: flex-start;
  display: flex;
  height: 27px;
  margin-left: 89px;
  margin-top: 30px;
  min-width: 216px;
  z-index: 3;
  @media (min-width: 1439px) and (max-width: 1877px) {
    transform: scale(0.94) translateX(-28px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    transform: scale(0.88) translateX(-70px) translateY(-10px);
  }
  @media (max-width: 1279px) {
    transform: scale(0.74) translateX(-130px) translateY(-25px);
  }
}

.l-how-we-work .place-14 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  min-height: 29px;
  min-width: 59px;
  text-decoration: underline;
  white-space: nowrap;
}

.l-how-we-work .angles-right-3 {
  align-self: center;
  height: 13px;
  margin-left: 4px;
  margin-top: 0;
  object-fit: cover;
  width: 15px;
}

.l-how-we-work .how-we-work-placeholder {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4.5px;
  margin-left: 4px;
  min-height: 29px;
  min-width: 134px;
  text-align: center;
  white-space: nowrap;
}

.l-how-we-work .span0-6 {
  text-decoration: underline;
}

.l-how-we-work .hww-shrinker {
  max-height: none;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-height: 970px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-height: 900px;
  }
  @media (max-width: 1279px) {
    max-height: 680px;
  }
  @media (max-width: 799px) {
    display: none;
  }
}

.l-how-we-work .why-we-exist-copy {
  height: 580px;
  margin-left: 89px;
  margin-right: 89px;
  margin-top: 75px;
  position: relative;
  width: 1603px;
  z-index: 5;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.82);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.74) translateY(-40px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.48) translateY(-240px);
  }
}

.l-how-we-work .overlap-group3-2 {
  height: 598px;
  left: 0;
  position: absolute;
  top: -6px;
  width: 1612px;
}

.l-how-we-work .ns1-close-up {
  height: 598px;
  left: 771px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 841px;
}

.l-how-we-work .risk-free-deployment {
  left: 0;
  letter-spacing: 0;
  line-height: 52px;
  position: absolute;
  top: 42px;
  width: 931px;
}

.l-how-we-work .rivelins-committed {
  height: 359px;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 155px;
  width: 771px;
}

.l-how-we-work .why-we-exist-copy-1 {
  height: 530px;
  margin-left: 88px;
  margin-right: 88px;
  margin-top: 81px;
  position: relative;
  width: 1608px;
  z-index: 4;
  @media (min-width: 1439px) and (max-width: 1877px) {
    margin: 0;
    transform: scale(0.82) translateY(-60px);
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    margin: 0;
    transform: scale(0.74) translateY(-140px);
  }
  @media (max-width: 1279px) {
    margin: 0;
    transform: scale(0.48) translateY(-710px);
  }
}

.l-how-we-work .overlap-group2-1 {
  height: 530px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1608px;
}

.l-how-we-work .pricing-modelyour-n {
  left: 720px;
  letter-spacing: 0;
  line-height: 52px;
  position: absolute;
  text-align: right;
  top: 64px;
  width: 888px;
}

.l-how-we-work .mask-group-4 {
  height: 530px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 769px;
}

.l-how-we-work .are-you-comfortable {
  left: 894px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  text-align: right;
  top: 183px;
  width: 702px;
}
