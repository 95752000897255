.story-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 77px;
  z-index: 4;
  width: 100%;
}

.overlap-group-container-1 {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  max-width: 1877px;
  @media (min-width: 1439px) and (max-width: 1877px) {
    max-width: 1439px;
  }
  @media (min-width: 1279px) and (max-width: 1439px) {
    max-width: 1279px;
  }
  @media (min-width: 799px) and (max-width: 1279px) {
    max-width: 799px;
  }
  @media (max-width: 1279px) {
    gap: 20px;
    align-items: center;
    flex-direction: column;
  }
}

.overlap-group2,
.overlap-group3,
.overlap-group4 {
  position: relative;
  width: 628px;
  @media (max-width: 799px) {
    max-width: 90vw;
  }
}

.overlap-group-14 {
  position: relative;
  height: 530px;
  width: 100%;
  @media (max-width: 799px) {
    // max-height: 110vw;
    height: auto;
    margin-bottom: 20px;
  }
}

.overlap-group2 .image {
  object-position: 40%;
}

.overlap-group3 .image {
  object-position: 38%;
}

.overlap-group4 .image {
  object-position: left;
}
.base {
  background-color: #ffffff;
  height: 141px;
  left: 0;
  position: absolute;
  top: 490px;
  width: 626px;
}

.image {
  height: 530px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 626px;
  @media (max-width: 799px) {
    margin: auto;
    height: 100%;
    width: 100%;
  }
}

.bio-wrapper {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(4px);
  background-color: #343638b0;
}

.bio-wrapper .icon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
}

.bio-wrapper .bio-text {
  height: 430px;
  overflow-y: scroll;
  padding: 0 42px 0 42px;
  margin: 50px 8px 50px 8px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  @media (max-width: 799px) {
    height: calc(100% - 70px);
    padding: 0 17px 0 17px;
    // margin: 25px 8px 25px 8px;
    font-size: 1.2em;
  }
}

.bio-wrapper .bio-text::-webkit-scrollbar {
  width: 6px;
}

.bio-wrapper .bio-text::-webkit-scrollbar-track {
  background-color: transparent;
}

.bio-wrapper .bio-text::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
}

.bio-wrapper .bio-text::-webkit-scrollbar-thumb:hover {
  background-color: #c2c6d4;
}

.name {
  margin-left: 5%;
  letter-spacing: 0.18px;
  width: 95%;
  .span0-3 {
    line-height: 29px;
    letter-spacing: 0.2px;
  }
}

.read-their-story-2 {
  cursor: pointer;
  height: 22px;
  margin-top: 20px;
  margin-left: 5%;
  min-width: 156px;
}
.read-their-story-2 .overlap-group1 {
  height: 24px;
  margin-top: -4px;
  position: relative;
  width: 156px;
}

.read-their-story-2 .read-their-story {
  left: 0;
  letter-spacing: 0.18px;
  line-height: 30px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.read-their-story-2 .icon-eye {
  height: 16px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 20px;
}
