.button-0-2-1 {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 0;
  min-width: 299px;
  position: absolute;
  top: 0;
}

.button-0-2-1 .overlap-group-13 {
  height: 40px;
  position: relative;
  width: 299px;
}

.button-0-2-1 .rectangle-450-1 {
  border: 2px solid #000000;
  border-radius: 60px;
  height: 299px;
  left: 130px;
  position: absolute;
  top: -130px;
  transform: rotate(-90deg);
  width: 40px;
}

.button-0-2-1 .connect-with-an-engineer {
  left: 12px;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.button-0-2-1.button-0-4,
.button-0-2-1.button-0-4-1,
.button-0-2-1.button-0-4-2 {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
}

.button-0-2-1.button-0-4-1:hover,
.button-0-2-1.button-0-4-2:hover,
.button-0-2-1.button-0-4:hover {
  opacity: 1;
}

.button-0-2-1.button-0-4-1 .rectangle-450-1,
.button-0-2-1.button-0-4-2 .rectangle-450-1,
.button-0-2-1.button-0-4 .rectangle-450-1 {
  background: linear-gradient(180deg, #e18700 0%, #f7ab21 60%, #ffe213 100%);
}
